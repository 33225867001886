import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
// import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
class BulkOrderRequests extends Component {
  state = {
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    trendId: 0,
    notifyTrending: false,
    visibleTrending: false,
    trending: false,
    rowId: 0,
    status: "",
    trendingStatus: "",
    active: 1,
    perPage: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    visibleDetails: false,
    Fname: "",
    Lname: "",
    email: "",
    mobile_no: "",
    designation: "",
    company: "",
    dob: "",
    city: "",
    address: "",
    productImage: "",
    productName: "",
    quantity: "",
    due_date_of_delivery: "",
    type_require: "",
  };

  componentDidMount() {
    this.getCategories();
  }

  getCategories = () => {
    axios
      .get(
        Constants.getUrls.bulkProducts +
          "?limit=" +
          this.state.perPage +
          "&skip=0&page=" +
          this.state.active
      )
      .then((resp) => {
        console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            data: resp.data.docs,
            total: resp.data.totalDocs,
            from: 1,
            to: resp.data.docs.length,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            Constants.getUrls.bulkProducts +
              "?limit=" +
              this.state.perPage +
              "&skip=0&page=" +
              this.state.active
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.totalPages; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                data: resp.data.docs,
                total: resp.data.totalDocs,
                from: 1,
                to: resp.data.docs.length,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  currentPage = (v) => {
    let commonUrl =
      Constants.getUrls.bulkProducts +
      "?limit=" +
      this.state.perPage +
      "&skip=0&page=";
    axios.get(commonUrl + v).then((resp) => {
      console.log(resp.data.data);
      this.setState(
        {
          data: resp.data.docs,
          total: resp.data.totalDocs,
          from: v * this.state.perPage - (this.state.perPage - 1),
          to:
            v * this.state.perPage > this.state.total
              ? this.state.total
              : v * this.state.perPage,
          active: v,
        },
        () => {
          console.log(typeof this.state.data[0].image, this.state.data);
        }
      );
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleStatus = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post(Constants.statusUrl + id + "?type=product", payload)
          .then(() => {
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState({ notifyStatus: false }, () => {
                this.getCategories();
              });
            }, 1000);
          });
      }
    );
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios.delete(Constants.getUrls.bulkProducts + "/" + id).then(() => {
      this.setState({
        notifyDelete: true,
        delLoading: false,
      });
      setTimeout(() => {
        this.setState({ notifyDelete: false, visible: false }, () => {
          this.getCategories();
        });
      }, 2000);
    });
  };
  handleTrending = (id) => {
    this.setState({ trending: true });
    axios
      .post(Constants.postUrls.setTrending + id, {
        trending_status: this.state.trendingStatus,
      })
      .then(() => {
        this.setState({
          notifyTrending: true,
          trending: false,
        });
        setTimeout(() => {
          this.setState(
            { notifyTrending: false, visibleTrending: false },
            () => {
              this.getCategories();
            }
          );
        }, 2000);
      });
  };
  handleModal = (sec, arg, status) => {
    this.setState({
      rowId: sec._id,
      trendId: sec._id,
      trendingStatus: status,
      visible: arg === "trending" ? false : true,
      visibleTrending: arg === "trending" ? true : false,
    });
  };
  
  openModal = (
    first_name,
    last_name,
    email,
    mobile,
    address,
    city,
    dob,
    company,
    designation,
    productChosenImage,
    productChosenName,
    quantity,
    due_date_of_delivery,
    type_require
  ) => {
    this.setState({
      visibleDetails: true,
      Fname: first_name,
      Lname: last_name,
      email: email,
      mobile_no: mobile,
      address: address,
      city: city,
      dob: dob,
      company: company,
      designation: designation,
      productImage: productChosenImage,
      productName: productChosenName,
      quantity: quantity,
      due_date_of_delivery: due_date_of_delivery,
      type_require: type_require,
    });
  };

  render() {
    return (
      <>
        <div className="content">
          {this.state.notifyStatus ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>Status Changed!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}
          <Row>
            <Col md="12">
              <Card style={{ position: "relative" }}>
                <FormGroup
                  style={{
                    position: "absolute",
                    top: "26px",
                    right: "2%",
                    width: "85px",
                  }}
                >
                  <label>Per Page</label>
                  <br />
                  <select
                    className="form-control"
                    name="perPage"
                    value={this.state.perPage}
                    onChange={this.handlePage}
                  >
                    {[10, 25, 50].map((category, i) => (
                      <option key={i} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </FormGroup>
                <CardHeader>
                  <CardTitle tag="h4">Bulk Order Requests Table</CardTitle>
                  {/* <Link to="/admin/addproduct">
                    <Button className="" color="success">
                      Add Bulk Order Requests
                    </Button>
                  </Link> */}
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Sr.No</th>
                        <th>User</th>
                        {/* <th>Due Date of Delivery</th> */}
                        <th>Custom Requirement</th>
                        <th>Product Choosen</th>
                        <th>Product Image</th>

                        <th>Quantity</th>
                        {/* <th>Type Require</th> */}
                        <th>Action</th>

                        {/* <th>Actions</th> */}
                        {/* <th>Trending</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((v, i) => (
                        <tr key={i}>
                          <td style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "30px" }}>
                              {i + 1}
                            </span>
                          </td>
                          <td style={{ position: "relative" }}>
                            {/* <span style={{ position: "absolute", top: "30px" }}> */}
                            <span>{v.first_name + " " + v.last_name}</span>
                            <br />
                            Email: <span>{v.email} </span>
                            <br />
                            Mobile: <span>{v.mobile_no} </span>
                            {/* </span> */}
                          </td>
                          {/* <td style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "30px" }}>
                              {v.due_date_of_delivery
                                ? new Date(
                                    v.due_date_of_delivery.slice(0, 10)
                                  ).toDateString()
                                : "NA"}
                            </span>
                          </td> */}
                          <td style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "30px" }}>
                              {v.custome_requirement
                                ? v.custome_requirement
                                : "NA"}
                            </span>
                          </td>
                          <td style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "30px" }}>
                              {v.product_choosen
                                ? v.product_choosen.name
                                : "NA"}
                            </span>
                          </td>
                          <td style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "30px" }}>
                              <img
                                src={Constants.imgUrl + v.product_choosen.image}
                                width="40%"
                                alt=""
                              />
                            </span>
                            {/* <span style={{ position: "absolute", top: "30px" }}>
                              {v.product_choosen
                                ? v.product_choosen.image
                                : "NA"}
                            </span> */}
                          </td>
                          <td style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "30px" }}>
                              {v.quantity ? v.quantity : "NA"}
                            </span>
                          </td>
                          {/* <td style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "30px" }}>
                              {v.type_require ? v.type_require : "NA"}
                            </span>
                          </td> */}
                          <td style={{ position: "relative" }}>
                            {/* <span style={{ position: "absolute", top: "30px" }}> */}
                            <Button
                              className="btn-round"
                              color="info"
                              type="submit"
                              onClick={() => {
                                this.openModal(
                                  v.first_name,
                                  v.last_name,
                                  v.email,
                                  v.mobile_no,
                                  v.address,
                                  v.city,
                                  v.dob,
                                  v.company,
                                  v.designation,
                                  v.product_choosen.image,
                                  v.product_choosen.name,
                                  v.quantity,
                                  v.due_date_of_delivery,
                                  v.type_require
                                );
                              }}
                            >
                              View Details
                            </Button>
                            {/* </span> */}
                          </td>

                          {/* <td>
                            <select
                              className="form-control"
                              name="status"
                              style={{ width: "100px" }}
                              value={v.status}
                            onChange={e => this.handleStatus(e, v._id)}
                            >
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="INACTIVE">INACTIVE</option>
                            </select>
                          </td> */}
                          {/* <td>
                            <Link
                              to={{
                                pathname: "/admin/updateproduct/" + v._id,
                                state: { data: v }
                              }}
                            >
                              <Button
                                className="btn-round"
                                color="info"
                                type="submit"
                              >
                                Update
                              </Button>
                            </Link>
                            <Button
                              className="btn-round"
                              color="danger"
                              type="submit"
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                this.handleModal(v,"delete");
                              }}
                            >
                              Delete
                            </Button>
                           
                           
                          </td>
                          <td>
                          {v.trending_status === "no" ? ( <Button
                              className="btn-round"
                              color="success"
                              type="submit"
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                this.handleModal(v,"trending","yes");
                              }}
                            >
                              Trend
                            </Button>):(
                               <Button
                               className="btn-round"
                               color="warning"
                               type="submit"
                               onClick={() => {
                                this.handleModal(v,"trending","no");
                              }}
                               style={{ marginLeft: "10px" }}
                             >
                               Trending
                             </Button>
                            )}
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                    <Modal
                      visible={this.state.visible}
                      width="600"
                      height="100"
                      effect="fadeInUp"
                      onClickAway={() => this.setState({ visible: false })}
                    >
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <Card>
                            {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                            <CardBody>
                              <div>
                                <h3>Are You Sure You Want to Delete It?</h3>
                                {this.state.notifyDelete ? (
                                  <div>
                                    <span style={{ color: "green" }}>
                                      Product Deleted Successfuly!
                                    </span>
                                  </div>
                                ) : null}
                                <Row>
                                  <Col xs={12} sm={12} md={4}>
                                    <br />
                                    <Button
                                      className=""
                                      color="warning"
                                      onClick={() => {
                                        this.handleDelete(this.state.rowId);
                                      }}
                                    >
                                      {this.state.delLoading
                                        ? "Deleting..."
                                        : "Yes"}
                                    </Button>
                                    <Button
                                      className=""
                                      color="success"
                                      onClick={() => {
                                        this.setState({ visible: false });
                                      }}
                                    >
                                      No
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Modal>
                    <Modal
                      visible={this.state.visibleTrending}
                      width="600"
                      height="100"
                      effect="fadeInUp"
                      onClickAway={() =>
                        this.setState({ visibleTrending: false })
                      }
                    >
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <Card>
                            {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                            <CardBody>
                              <div>
                                <h3>
                                  Are You Sure You Want to{" "}
                                  {this.state.trendingStatus === "yes"
                                    ? "Trend"
                                    : "Untrend"}{" "}
                                  It?
                                </h3>
                                {this.state.notifyTrending ? (
                                  <div>
                                    <span style={{ color: "green" }}>
                                      {this.state.trendingStatus === "yes"
                                        ? "Added To Trending Successfuly!"
                                        : "Removed From Trending Successfuly!"}
                                    </span>
                                  </div>
                                ) : null}
                                <Row>
                                  <Col xs={12} sm={12} md={4}>
                                    <br />
                                    <Button
                                      className=""
                                      color="success"
                                      onClick={() => {
                                        this.handleTrending(this.state.trendId);
                                      }}
                                    >
                                      {this.state.trending
                                        ? this.state.trendingStatus === "yes"
                                          ? "Trending..."
                                          : "Untrnding..."
                                        : "Yes"}
                                    </Button>
                                    <Button
                                      className=""
                                      color="danger"
                                      onClick={() => {
                                        this.setState({
                                          visibleTrending: false,
                                        });
                                      }}
                                    >
                                      No
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Modal>
                    <Modal
                      visible={this.state.visibleDetails}
                      width="600"
                      height="600"
                      // effect="fadeInUp"
                      onClickAway={() =>
                        this.setState({ visibleDetails: false })
                      }
                    >
                      <div
                        style={{ padding: "20px" }}
                        // className="modalWrap"
                      >
                        <span>
                          <img
                            src={Constants.imgUrl + this.state.productImage}
                            width="20%"
                            alt=""
                          />{" "}
                        </span>
                        <span> {this.state.productName}</span>
                        <p style={{ marginTop: "10px" }}>
                          {" "}
                          <b>Name :</b>{" "}
                          {this.state.Fname + " " + this.state.Lname}{" "}
                        </p>
                        <p>
                          <b>Email :</b> {this.state.email}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Mobile No :</b> {this.state.mobile_no}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Address :</b>{" "}
                          {this.state.address + " " + this.state.city}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Date of Birth :</b>{" "}
                          {new Date(this.state.dob).toDateString().slice(4, 19)}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Company :</b> {this.state.company}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Designation :</b> {this.state.designation}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Quantity : </b> {this.state.quantity}{" "}
                        </p>
                        <p>
                          {" "}
                          <b> Expected Delivery date : </b>{" "}
                          {new Date(this.state.due_date_of_delivery)
                            .toDateString()
                            .slice(4, 19)}
                        </p>
                        <p>
                          <b> Type Required : </b> {this.state.type_require}
                        </p>
                      </div>
                    </Modal>
                  </Table>
                  {this.state.data.length === 0 ? (
                    <div>
                      <h6
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          fontSize: "14px",
                        }}
                      >
                        No Data Found!
                      </h6>
                    </div>
                  ) : (
                    <div style={{ marginTop: "20px" }}>
                      <div style={{ float: "left" }}>
                        Showing {this.state.from} to {this.state.to} of{" "}
                        {this.state.total} entries.
                      </div>

                      <div style={{ float: "right" }}>
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.prevPages}
                              tabIndex="-1"
                            >
                              <i className="fa fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          {this.state.filteredBtnIdArray.map((v, i) => (
                            <PaginationItem
                              className={
                                this.state.active === v ? "active" : ""
                              }
                              key={i}
                            >
                              <PaginationLink
                                onClick={() => {
                                  this.currentPage(v);
                                }}
                              >
                                {v}
                              </PaginationLink>
                            </PaginationItem>
                          ))}
                          <PaginationItem>
                            <PaginationLink onClick={this.nextPages}>
                              <i className="fa fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default BulkOrderRequests;
