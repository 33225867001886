import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  UncontrolledAlert,
  Input,
} from "reactstrap";
import { Select, TextField } from "@material-ui/core";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import MultiSelect from "react-multi-select-component";
import Autocomplete from "@material-ui/lab/Autocomplete";
class AddBestSeller extends Component {
  state = {
    productIds: [],
    products: [],
    vendors: [],
    vendor: "",
    warn: false,
    redirect: false,
    warning: false,
    notification: false,
    loading: false,
    super_categories: [],
    super_category: "",
    categories: [],
    category: "",
    sub_categories: [],
    sub_category: "",
    search: "",
    all_filter_product: [],
    selectedID: "",
  };
  componentDidMount() {
    // GET all Vendor API

    // axios.get(Constants.getUrls.vendors + "?limit=20&skip=0&page=0").then(resp => {
    //   console.log(resp.data);
    //   this.setState({
    //     vendors: resp.data.docs
    //   });
    // });

    // Get All Super Category
    axios
      .get(
        Constants.getUrls.super_categories +
          "?limit=100" +
          "&skip=0&page=1" +
          "&is_delete=no&status=ACTIVE"
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          super_categories: resp.data.superCat.docs,
        });
      });
  }

  handleCategories = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        category: "",
      },
      () => {
        axios
          .get(
            Constants.getUrls.categories +
              "?limit=50&page=1&status=ACTIVE" +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              categories: resp.data.docs,
            });
          });
      }
    );
  };

  handleSubCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.subCategories +
              "?limit=50&page=1&status=ACTIVE" +
              "&category=" +
              this.state.category +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              sub_categories: resp.data.docs,
            });
          });
      }
    );
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSearch = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.search);
        axios
          .get(
            Constants.getUrls.BestproductSearch +
              "?super_category=" +
              this.state.super_category +
              "&category=" +
              this.state.category +
              "&sub_category=" +
              this.state.sub_category +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp);
            if (resp.data.getAllProduct.length == 0) {
              this.setState({
                all_filter_product: [
                  {
                    name: "No Product Found!",
                    _id: "0",
                  },
                ],
              });
            }
            if (resp.data.getAllProduct.length > 0) {
              this.setState({
                all_filter_product: resp.data.getAllProduct,
              });
            }
          });
      }
    );
  };

  handleSelect = (e, value) => {
    console.log(value);

    axios
      .get(
        "https://node.thespaceelement.com:4005/best_seller/prodSearch" +
          "?super_category=" +
          this.state.super_category +
          "&category=" +
          this.state.category +
          "&sub-category=" +
          this.state.sub_category +
          "&s=" +
          value +
          "&is_delete=no"
      )
      .then((resp) => {
        // if (resp.data.getAllProduct.docs.length > 0) {
        //   // productIds;
        // }
        this.setState({
          subCategories: resp.data.docs,
        });
      });
  };

  // Vendor Function

  // handleVendor = e => {
  //   if (e.target.value !== "") {
  //     this.setState({
  //       vendor: e.target.value
  //     }, () => {
  //       let products = this.state.vendors.find(v => v._id === this.state.vendor).products;
  //       console.log(products);
  //       products.forEach(v => {
  //         this.state.products.push({
  //           label: v.name,
  //           value: v._id
  //         });
  //       });
  //       this.setState({ products: this.state.products, productIds: [] })
  //     });
  //   }
  // };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.selectedID === "") {
      this.setState({ warn: true });
    } else {
      this.setState({
        loading: true,
      });
      let products = [];
      products.push(this.state.selectedID);
      let payLoad = {
        super_category: this.state.super_category,
        category: this.state.category,
        sub_category: this.state.sub_category,
        products,
      };
      // console.log(payLoad)
      // return

      axios.post(Constants.getUrls.bestSellers, payLoad).then((resp) => {
        // console.log(resp);
        if (resp.data.status === "suucess") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warning: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warning: false,
            });
          }, 1000);
        }
      });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/bestsellers"} />;
    } else {
      return (
        <div className="content">
          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>Best Seller Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          {this.state.warn ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-dislike-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>This BestSeller Already Addded!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Add Best Seller</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      {/* Select modal for Vendor */}

                      {/* <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Vendor</label>
                            <br />
                            <select
                              className="form-control"
                              name="vendor"
                              required={true}
                              onChange={this.handleVendor}
                            >
                              <option value="">Select an Option</option>
                              {this.state.vendors.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}

                      {/* Select Super Category */}

                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>Super Category</label>
                          <br />
                          <select
                            className="form-control"
                            name="super_category"
                            required={true}
                            onChange={this.handleCategories}
                          >
                            <option value="">Select an Option</option>
                            {this.state.super_categories.map((v, i) => (
                              <option key={i} value={v._id}>
                                {v.title}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>

                      {/* Select Category */}

                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>Category</label>
                          <br />
                          <select
                            className="form-control"
                            name="category"
                            required={true}
                            onChange={this.handleSubCategory}
                          >
                            <option value="">Select an Option</option>
                            {this.state.categories.map((v, i) => (
                              <option key={i} value={v._id}>
                                {v.name}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>

                      {/* Select Sub Category */}

                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>Sub Category</label>
                          <br />
                          <select
                            className="form-control"
                            name="sub_category"
                            required={true}
                            onChange={this.handleSearch}
                          >
                            <option value="">Select Category First!</option>
                            {this.state.sub_categories.map((v, i) => (
                              <option key={i} value={v._id}>
                                {v.name}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>

                      {/* 
                        <Col className="pr-1" md="4">
                          <label>Search Products</label>
                          <br />
                          <Autocomplete
                            // style={{ width: "425px" }}
                            freeSolo
                            autoComplete
                            autoHighlight
                            autoSelect
                            isOptionEqualToValue
                            // value={this.state.stocks_name}
                            options={
                              this.state.all_filter_product
                                ? this.state.all_filter_product.map(
                                    (option) => option.name
                                  )
                                : ""
                            }
                            onChange={(e) => {
                              this.handleSelect(e);
                            }}
                            // clearOnEscape
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="search"
                                value={this.state.search}
                                onChange={this.handleSearch}
                                variant="outlined"
                                label="Search Box"
                              />
                            )}
                          />
                        </Col> */}

                      <Col className="pr-1" md="4">
                        {/* <Input
                            placeholder="Search Product"
                            name="search"
                            onChange={this.handleSearch}
                            required={true}
                            type="text"
                          /> */}
                        <label htmlFor="Products">Products</label>
                        {/* <MultiSelect/> */}
                        {/* <MultiSelect
                            // disableSearch
                            className="form-control"
                          //  name="productIds"

                            options={this.state.products}
                            value={this.state.productIds}
                            onChange={(data) => {
                              this.setState({ productIds: data }, () => {
                                if (this.state.productIds.length === 0) {
                                  this.setState({ warn: true });
                                } else {
                                  this.setState({ warn: false });
                                }
                              });
                            }}
                            labelledBy={"Products"}
                          />
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null} */}

                        {/* //product==== */}
                        <select
                          className="form-control"
                          name="products"
                          value={this.state.selectedID}
                          required={true}
                          // onChange={this.handleInput}

                          onChange={(e) => {
                            this.setState({
                              selectedID: e.target.value,
                            });
                          }}
                        >
                          <option value="" style={{ height: "5px" }}>
                            Select an Option
                          </option>
                          {this.state.all_filter_product.map((v, i) => (
                            <option key={i} value={v._id}>
                              {v.name}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "10rem",
                      }}
                    ></div>
                    <Row>
                      <br />

                      <Col className="pr-1" md="6">
                        <Button
                          className="btn-round"
                          color="success"
                          type="submit"
                        >
                          {this.state.loading ? "Adding..." : "Add Best Seller"}
                        </Button>
                        <Link to="/admin/bestsellers">
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

export default AddBestSeller;
