import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
class AddMobileSlider extends Component {
  state = {
    name: "",
    link: "",
    note: "",
    category: "",
    subCategory: "",
    categories: [],
    subCategories: [],
    image: [],
    description: "",
    redirect: false,
    catVisible: false,
    subCatVisible: false,
    warn: false,
    notification: false,
    loading: false,
    // image Preview
    imageFile: "",
    photoUrl: "",
    imagePreview: false,
  };
  componentDidMount = () => {
    // axios.get(Constants.getUrls.categories + "?limit=20&skip=0&page=0").then(resp => {
    //     // console.log(resp.data.data);
    //     this.setState({
    //         categories: resp.data.docs
    //     });
    // });
    // axios.get(Constants.getUrls.subCategories + "?limit=50&skip=0&page=0").then(resp => {
    //     // console.log(resp.data.data);
    //     this.setState({
    //         subCategories: resp.data.docs
    //     });
    // });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  // image Preview handle
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let fd = new FormData();
    console.log(this.state.image);
    fd.append("image", this.state.image);
    if (this.state.link.length > 0) {
      fd.append("link", this.state.link);
    }
    //===link ke sath category subcategory===

    // if (this.state.category !== "" && this.state.catVisible) {
    //     fd.append("link", "http://fakeurl.com?catId=" + this.state.category);
    // }
    // if (this.state.subCategory !== "" && this.state.subCatVisible) {
    //     fd.append("link", "http://fakeurl.com?subCatId=" + this.state.subCategory);
    // }

    if (this.state.description.length > 0) {
      fd.append("description", this.state.description);
    }
    if (this.state.note.length > 0) {
      fd.append("note", this.state.note);
    }
    fd.append("title", this.state.name);
    axios.post(Constants.getUrls.mobileHomeSliders, fd).then((resp) => {
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warn: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warn: false,
          });
        }, 1000);
      }
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/mobilehomesliders"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Mobile Home Slider Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong> This Silder Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Mobile Home Slider</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              placeholder="Name"
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col style={{ marginTop: "8px" }} md="6">
                          <Row>
                            <Col style={{ marginTop: "8px" }} md="6">
                              <label>Image(1114*350)</label>
                              <br />
                              <input
                                type="file"
                                onChange={(e) => {
                                  this.handleFile(e);
                                  this.handlePreview(e);
                                  this.setState({ imagePreview: true });
                                }}
                                required={true}
                                name="image"
                              />
                            </Col>
                            {this.state.imagePreview ? (
                              <Col style={{ marginTop: "8px" }} md="6">
                                <label>Image Preview</label>
                                <br />
                                <img
                                  src={this.state.photoUrl}
                                  width={100}
                                  alt=""
                                />
                              </Col>
                            ) : null}
                          </Row>
                        </Col>
                        <Col className="pr-1" md="12">
                          <FormGroup>
                            <label>Link</label>
                            <Input
                              placeholder="Link"
                              name="link"
                              onChange={this.handleInput}
                              required={false}
                              type="link"
                            />
                          </FormGroup>
                        </Col>

                        {/* <Col className="pr-1" md="6">
                                                    <FormGroup>
                                                        <label>Link</label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            onChange={e => {
                                                                if (e.target.value === "Category") {
                                                                    this.setState({ catVisible: true, subCatVisible: false })
                                                                }
                                                                else if (e.target.value === "Subcategory") {
                                                                    this.setState({ subCatVisible: true, catVisible: false })
                                                                } else {
                                                                    this.setState({ catVisible: false, subCatVisible: false });
                                                                }
                                                            }}
                                                        >
                                                            <option value="">Select an Option</option>
                                                            {["Category", "Subcategory"].map((v, i) => (
                                                                <option key={i} value={v}>
                                                                    {v}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                {this.state.catVisible ? (<Col style={{ marginTop: "32.5px" }} className="pr-1" md="6">
                                                    <FormGroup>
                                                        <select
                                                            className="form-control"
                                                            name="category"
                                                            onChange={e => { this.setState({ category: e.target.value, subCategory: "" }) }}
                                                        >
                                                            <option value="">Select an Option</option>
                                                            {this.state.categories.map((v, i) => (
                                                                <option key={i} value={v._id}>
                                                                    {v.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>) : this.state.subCatVisible ? (<Col style={{ marginTop: "32.5px" }} className="pr-1" md="6">
                                                    <FormGroup>
                                                        <select
                                                            className="form-control"
                                                            name="category"
                                                            onChange={e => { this.setState({ subCategory: e.target.value, category: "" }) }}
                                                        >
                                                            <option value="">Select an Option</option>
                                                            {this.state.subCategories.map((v, i) => (
                                                                <option key={i} value={v._id}>
                                                                    {v.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>) : null} */}

                        <Col md="12">
                          <label>Note</label>
                          <CKEditor
                            required={false}
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                note: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col md="12">
                          <label>Description</label>
                          <CKEditor
                            required={false}
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                description: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading
                              ? "Adding..."
                              : "Add Mobile Home Slider"}
                          </Button>
                          <Link to="/admin/mobilehomesliders">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddMobileSlider;
