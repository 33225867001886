import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import MultiSelect from "react-multi-select-component";
import ReactDatetime from "react-datetime";
class UpdateCouponCode extends Component {
  state = {
    name: "",
    code: "",
    discount: "",
    id: "",
    type: "",
    strDate: "",
    endDate: "",
    productIds: [],
    products: [],
    warn1: false,
    warn2: false,
    warn3: false,
    redirect: false,
    warn: false,
    notification: false,
    loading: false
    
  };
  componentDidMount() {
    // console.log(this.props);
    axios
      .get(Constants.getUrls.products + "?limit=500&skip=0&page=0" + "&is_delete=no")
      .then(resp => {
        // console.log(resp.data);
        resp.data.docs.forEach(v => {
          this.state.products.push({
            label: v.name,
            value: v._id
          });
        });
        this.setState({
          products: this.state.products
        }, () => {
          let data = this.props.location.state.data;
          //  console.log()
          data.products.forEach(v => {
            this.state.productIds.push({
              label: v.name,
              value: v._id
            });
          });
          this.setState({
            name: data.coupon_name,
            code: data.coupon_code,
            type: data.offer_type,
            discount: data.discount,
            strDate: data.start_date.slice(0, 10),
            endDate: data.end_date.slice(0, 10),
            // productIds: this.state.productIds,
            id: data._id
          });
        });
      });

  }
  handleDate = (e, type) => {
    let year = e._d.getFullYear().toString();
    let month = (e._d.getMonth() + 101)
      .toString()
      .substring(1);
    let day = (e._d.getDate() + 100)
      .toString()
      .substring(1);
    if (type === "strDate") {
      this.setState(
        {
          strDate: year + "-" + month + "-" + day
        }
      );
    }
    else {
      this.setState(
        {
          endDate: year + "-" + month + "-" + day
        }
      );
    }

  }
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    // if (this.state.productIds.length === 0) {
    //   this.setState({warn1:true});
    // } else
    if (this.state.strDate === "") {
      this.setState({ warn2: true });
    } else if (this.state.endDate === "") {
      this.setState({ warn3: true });
    } else {
      this.setState({
        loading: true
      });
      // let prodIds = [];
      // console.log(this.state.productIds);
      // if (this.state.productIds.length > 0) {
      //   this.state.productIds.forEach(v => {
      //     prodIds.push(v.value)
      //   })
      // }
      let payLoad = {
        coupon_name: this.state.name,
        offer_type: this.state.type,
        coupon_code: this.state.code,
        discount: this.state.discount,
        start_date: this.state.strDate,
        end_date: this.state.endDate,
        // prodIds: prodIds
      };
      console.log(payLoad);
      axios
        .put(Constants.getUrls.coupons + "/" + this.state.id, payLoad)
        .then((resp) => {
          if (resp.data.status === "success") {
            setTimeout(() => {
              this.setState({
                redirect: true
              });
            }, 1000);
            this.setState({
              notification: true,
              loading: false
            });
          } else {
            this.setState({
              warn: true,
              loading: false
            });
            setTimeout(() => {
              this.setState({
                warn: false
              });
            }, 1000);
          }
        });
    };
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/couponcodes"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div style={{ width: "250px", position: "relative", left: "40%", top: "7px" }}>
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Coupon Code Updated!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div style={{ width: "250px", position: "relative", left: "40%", top: "7px" }}>
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Some Error Occured!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Coupon Code</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              placeholder="Name"
                              name="name"
                              value={this.state.name}
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Code</label>
                            <Input
                              placeholder="Code"
                              name="code"
                              value={this.state.code}
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col style={{ marginTop: "4px" }} className="pr-1" md="4">
                          <label htmlFor="Courses">Products</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.products}
                            value={this.state.productIds}
                            onChange={data => {
                              this.setState({ productIds: data });
                            }}
                            labelledBy={"Products"}
                          />
                          {this.state.warn1 ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </Col> */}
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Offer Type</label>
                            <br />
                            <select
                              className="form-control"
                              name="type"
                              value={this.state.type}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {[{ label: "Amount", value: "amount" }, { label: "Percent", value: "percent" }].map((v, i) => (
                                <option key={i} value={v.value}>
                                  {v.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Discount</label>
                            <Input
                              placeholder="Discount"
                              name="discount"
                              value={this.state.discount}
                              onChange={this.handleInput}
                              required={true}
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col style={{ marginTop: "32px" }} className="pr-1" md="4">
                          <ReactDatetime
                            value={this.state.strDate}
                            inputProps={{
                              placeholder: "Date Picker Here"
                            }}
                            onChange={e => {
                              this.handleDate(e, "strDate")
                            }}
                            timeFormat={false}
                          />
                          {this.state.warn2 ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                          <br />
                        </Col>
                        <Col style={{ marginTop: "32px" }} className="pr-1" md="4">
                          <ReactDatetime
                            value={this.state.endDate}
                            inputProps={{
                              placeholder: "Date Picker Here",
                            }}
                            onChange={e => {
                              this.handleDate(e, "endDate")
                            }}
                            timeFormat={false}
                          />
                          {this.state.warn3 ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </Col>

                      </Row>
                      <Row>
                        <Col style={{ marginTop: "8px" }} className="pr-1" md="4">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Updating..." : "Update Coupon Code"}
                          </Button>
                          <Link to="/admin/couponcodes">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdateCouponCode;
