// import React, { Component } from 'react'

// export default class UpdateExchangeProduct extends Component {
//   render() {
//     return (
//       <div>UpdateExchangeProduct</div>
//     )
//   }
// }

import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import MultiSelect from "react-multi-select-component";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Added by Nehal Prasad on 29/03/2023
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Grid, Box, Modal } from "@material-ui/core";

class UpdateExchangeProduct extends Component {
  state = {
    name: "",
    commission: "",
    categories: [],
    category: "",
    productIds: [],
    products: [],
    subCategories: [],
    deliveryModes: [],
    deliveryMode: "",
    sub_category: "",
    overview: "",
    note: "",
    charge: "",
    specification: "",
    taxType: "",
    filteredProductsFor: [],
    filteredSubCategories: [],
    taxTypes: [],
    price: "",
    offerType: "",
    value: "",
    quantity: "",
    customizeProduct: "",
    vendors: [],
    // image: [],

    image: "",
    video: [],
    customizeImage: [],
    productsFor: [],
    productFor: "",
    // description: "",
    short_description: "",
    vendor: "",
    videoAdded: false,
    customize: false,
    img1Updated: false,
    img2Updated: false,
    redirect: false,
    warn: false,
    notification: false,
    warning: false,
    loading: false,
    super_category: "",
    super_categories: [],
    is_return: "",
    imageSelected: [],

    // images1: "",
    firstImage: "",
    firImg: null,
    isfirstImageOpen: false,
    firstImageCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    images2: "",
    secImg: null,
    isSecondImageOpen: false,
    secondImageCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    images3: "",
    thirdImg: null,
    isThirdImageOpen: false,
    ThirdImageCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    images4: "",
    forthImg: null,
    isForthImageOpen: false,
    ForthImageCrop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },

    // Added by Nehal Prasad on 29/03/2023
    images1: "",
    pics: [],
    selectedFiles: [],

    myArray: [],
    myArrayLength: "",

    selectedImage: "",
    currenIndex: 0,

    imageCrop: {
      unit: "%",
      x: "",
      y: "",
      width: 60,
      height: 70,
    },

    croppedImage: [],
    firstpic: "",
    secondpic: "",
    thirdpic: "",
    fourthpic: "",
    fifthpic: "",
    modalVisible: "false",
    finalImage: [],
  };
  componentDidMount() {
    let data = this.props.location.state.data;

    this.setState(
      {
        name: data.name,
        charge: data.delivery_amount,
        category: data.category ? data.category._id : "",
        productFor: data.product_for ? data.product_for._id : "",
        sub_category: data.sub_category ? data.sub_category._id : "",
        vendor: data.vendor ? data.vendor._id : "",
        commission: data.vendor_commission ? data.vendor_commission : "",
        deliveryMode: data.delivery_mode ? data.delivery_mode._id : "",
        overview: data.product_overview ? data.product_overview : "",
        note: data.note ? data.note : "",
        specification: data.specification ? data.specification : "",
        taxType: data.tax_type ? data.tax_type._id : "",
        price: data.price,
        offerType: data.offer_type ? data.offer_type : "",
        value: data.offer_value ? data.offer_value : "",
        quantity: data.product_quantity,
        // description: data.customize_product_description,
        short_description: data.short_description,

        // customizeImage: data.customize_product_image,
        customizeProduct: data.customize_product,
        super_category: data.super_category ? data.super_category._id : "",
        id: data._id,
        image: data.image ? data.image : "",
      },
      () => {
        console.log(this.state.image);
        console.log(data);
        // Category API
        axios
          .get(
            Constants.getUrls.categories +
              "?limit=50&page=1&status=active" +
              `&super_category=` +
              this.state.super_category
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              categories: resp.data.docs,
            });
          });
        // Sub Category API
        axios
          .get(
            Constants.getUrls.subCategories +
              "?limit=50&page=1&status=active" +
              `&super_category=` +
              this.state.super_category +
              "&category=" +
              this.state.category
          )
          .then((resp) => {
            console.log(resp.data);
            this.setState({
              subCategories: resp.data.docs,
            });
          });
      }
    );
    axios
      .get(Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1")
      .then((resp) => {
        // console.log(resp);
        this.setState({
          super_categories: resp.data.superCat.docs,
        });
      });
    // Product For APi
    axios
      .get(Constants.getUrls.productsFor + "?limit=50&skip=0&page=0")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          productsFor: resp.data.docs,
        });
      });

    axios
      .get(
        Constants.getUrls.categories +
          "?limit=50&page=1&status=active" +
          `&super_category=` +
          this.state.super_category
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          categories: resp.data.docs,
        });
      });
    // sub categories
    axios
      .get(
        Constants.getUrls.subCategories +
          "?limit=50&page=1&status=active" +
          `&super_category=` +
          this.state.super_category +
          "&category=" +
          this.state.category
      )
      .then((resp) => {
        console.log(resp.data);
        this.setState({
          subCategories: resp.data.docs,
        });
      });
    axios
      .get(Constants.getUrls.vendors + "?limit=50&skip=0&page=0")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          vendors: resp.data.docs,
        });
      });
    axios
      .get(Constants.getUrls.taxTypes + "?limit=50&skip=0&page=0")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          taxTypes: resp.data.docs,
        });
      });
    axios
      .get(Constants.getUrls.deliveryModes + "?limit=50&skip=0&page=0")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          deliveryModes: resp.data.docs,
        });
      });
  }
  handleCatgory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.categories +
              "?limit=50&page=1&status=active" +
              `&super_category=` +
              this.state.super_category
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              categories: resp.data.docs,
            });
          });
      }
    );
  };
  handleSubCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.subCategories +
              "?limit=50&page=1&status=active" +
              `&super_category=` +
              this.state.super_category +
              "&category=" +
              this.state.category
          )
          .then((resp) => {
            // console.log(resp.data.data);
            this.setState({
              subCategories: resp.data.docs,
            });
          });
      }
    );
  };
  handleProductFor = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //  Products For API
        axios
          .get(
            Constants.getUrls.productsFor +
              "/" +
              this.state.category +
              "?limit=50&skip=1&page=1"
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              productsFor: resp.data,
            });
          });
      }
    );
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleVideo = (e) => {
    this.setState({ [e.target.name]: e.target.files[0], videoAdded: true });
  };
  handleFile1 = (e) => {
    this.setState(
      { [e.target.name]: e.target.files, img1Updated: true },
      () => {
        console.log(this.state.image);
      }
    );
  };
  handleFile2 = (e) => {
    this.setState({ [e.target.name]: e.target.files[0], img2Updated: true });
  };

  handleFinish = (e) => {
    this.setState({
      modalVisible: false,
    });

    const { croppedImage, firstpic, secondpic, thirdpic, fourthpic, fifthpic } =
      this.state;

    if (firstpic) {
      croppedImage.push(firstpic);
      console.log(firstpic);
    }
    if (secondpic) {
      croppedImage.push(secondpic);
      console.log(secondpic);
      console.log(typeof secondpic);
    }
    if (thirdpic) {
      croppedImage.push(thirdpic);
    }
    if (fourthpic) {
      croppedImage.push(fourthpic);
    }
    if (fifthpic) {
      croppedImage.push(fifthpic);
    }

    // const finalObj = [...firstpic, ...secondpic, ...thirdpic];

    // console.log(finalObj)

    // this.setState({
    //   finalImage:finalObj
    // })

    // console.log(croppedImage)
    // const objectkeys = Object.keys(croppedImage)

    // console.log(objectkeys)

    // const objectValue = Object.values(croppedImage)

    // console.log(objectValue)
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });
    let prodIds = [];
    // console.log(this.state.productIds);
    this.state.productIds.forEach((v) => {
      prodIds.push(v.value);
    });
    let fd = new FormData();
    fd.append("product_for", this.state.productFor);
    fd.append("name", this.state.name);
    fd.append("catId", this.state.category);
    if (this.state.videoAdded) {
      fd.append("video", this.state.video);
    }
    fd.append("superCatId", this.state.super_category);
    fd.append("subcatId", this.state.sub_category);
    fd.append("delivery_modeId", this.state.deliveryMode);
    fd.append("delivery_amount", this.state.charge);
    if (this.state.image) {
      for (let i = 0; i < this.state.image.length; i++) {
        fd.append("image", this.state.image[i]);
      }
    }

    if (this.state.firstpic) {
      const firstSideImage = await fetch(this.state.firstpic[0])
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.myArray[0], {
              type: blobFile.type,
            })
        );
      fd.append("image", firstSideImage);
    }

    if (this.state.secondpic) {
      const SecondSideImage = await fetch(this.state.secondpic)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.myArray[1], {
              type: blobFile.type,
            })
        );
      fd.append("image", SecondSideImage);
    }

    if (this.state.thirdpic) {
      const ThirdSideImage = await fetch(this.state.thirdpic)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.myArray[2], {
              type: blobFile.type,
            })
        );
      fd.append("image", ThirdSideImage);
    }

    if (this.state.fourthpic) {
      const ForthSideImage = await fetch(this.state.fourthpic)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.myArray[3], {
              type: blobFile.type,
            })
        );
      fd.append("image", ForthSideImage);
    }

    if (this.state.fifthpic) {
      const FifthSideImage = await fetch(this.state.fifthpic)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], this.state.myArray[4], {
              type: blobFile.type,
            })
        );
      fd.append("image", FifthSideImage);
    }

    // console.log(firstSideImage)

    // console.log(SecondSideImage)

    // console.log(ThirdSideImage)

    // console.log(ForthSideImage)
    //     if(this.state.firstpic){
    //       console.log(this.state.firstpic[0])
    //       fd.append("image" , firstSideImage)
    //     }
    //     if(this.state.secondpic){
    //       console.log(this.state.secondpic[0])
    //       fd.append("image" , SecondSideImage)
    //     }
    //     if(this.state.thirdpic){
    //       fd.append("image" , ThirdSideImage)
    //     }
    //     if(this.state.fourthpic){
    //       fd.append("image" , ForthSideImage)
    //     }
    //     if(this.state.fifthpic){
    //       fd.append("image" , FifthSideImage)
    //     }

    // if(this.state.finalImage !== null){
    //   for(let i = 0; i<this.state.finalImage; i++){
    //     // fd.append("image")
    //     console.log(this.state.finalImage[i])
    //   }
    // }

    // if(this.state.finalImage !== null){
    //   fd.append("image", this.state.finalImage)
    //  }

    // for (let i = 0; i < this.state.image.length; i++) {
    //   fd.append("image", this.state.image[i]);
    // }

    // if (this.state.croppedImage !== null) {

    //   console.log(this.state.croppedImage)
    // for (let i = 0; i < this.state.croppedImage.length; i++) {
    // fd.append("image", this.state.croppedImage);
    // console.log("beb", this.state.croppedImage[i])
    // }
    // }
    fd.append("price", this.state.price);
    if (this.state.specification !== "") {
      fd.append("specification", this.state.specification);
    }
    if (this.state.note !== "") {
      fd.append("note", this.state.note);
    }
    if (this.state.overview !== "") {
      fd.append("product_overview", this.state.overview);
    }
    fd.append("tax_type", this.state.taxType);
    if (this.state.offerType !== "") {
      fd.append("offer_type", this.state.offerType);
    }
    if (this.state.value !== "") {
      fd.append("offer_value", this.state.value);
    }
    // fd.append("printIds", JSON.stringify(prodIds));
    fd.append("product_quantity", this.state.quantity);
    fd.append("is_return", this.state.is_return);
    // fd.append("customize_product", this.state.customizeProduct);
    //   if (this.state.customizeProduct === "yes") {
    //     if (this.state.img2Updated) {
    //   fd.append("customize_product_image", this.state.customizeImage);
    // }
    //   fd.append("customize_product_description", this.state.description);
    fd.append("short_description", this.state.short_description);
    // fd.append("image", this.state.image);
    // }
    // for (var pair of fd.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    axios
      .put(Constants.getUrls.products + "/" + this.state.id, fd)
      .then((resp) => {
        if (resp.data.status === "success") {
          setTimeout(() => {
            console.log(resp);
            this.setState({
              redirect: true,
            });
          }, 3000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warning: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warning: false,
            });
          }, 3000);
        }
      });
    // };
  };

  refForImages = (pics) => {
    pics.map((key, index) => {
      const obj = URL.createObjectURL(key);
      console.log(obj);
      this.state.myArray.push(obj);
    });

    console.log(this.state.myArray);
    const text = this.state.myArray;
    console.log(text.length);
    this.setState({
      myArrayLength: text.length,
    });
  };

  onImageLoaded = (image) => {
    // console.log(image)
    this.imageRef = image;
  };

  onImageCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // console.log(crop)
    // this.setState({ crop: percentCrop });
    this.setState({ imageCrop: crop });
    // console.log(this.state.imageCrop)
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  async makeClientCrop(crop) {
    // console.log(crop)
    // console.log(this.imageRef)
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );

      let obj = [];
      obj.push(croppedImageUrl);

      if (this.state.currenIndex === 0) {
        this.setState({
          firstpic: obj.splice(-1),
        });
      }

      if (this.state.currenIndex === 1) {
        this.setState({
          secondpic: obj.splice(-1),
        });
      }

      if (this.state.currenIndex === 2) {
        this.setState({
          thirdpic: obj.splice(-1),
        });
      }
      if (this.state.currenIndex === 3) {
        this.setState({
          fourthpic: obj.splice(-1),
        });
      }
      if (this.state.currenIndex === 4) {
        this.setState({
          fifthpic: obj.splice(-1),
        });
      }
    }
  }

  // handleImageFile = (e) => {

  //   console.log(e.target.files)
  //   const selectedFiles = Array.from(e.target.files)
  //   console.log(selectedFiles)

  //   if(selectedFiles.length > 0){
  //     this.setState({
  //       modalVisible: true
  //     })
  //   }
  //   e.persist();
  //   if (e.target.files[0]) {
  //     this.setState(
  //       {
  //         [e.target.name]: e.target.files[0],
  //         pics: e.target.files,
  //         selectedFiles: selectedFiles,
  //         currentIndex: 0
  //       },
  //       () => {
  //         this.refForImages(selectedFiles);
  //       }
  //     );
  //   }
  // };

  // handleNext = () => {
  //   this.setState({
  //     currenIndex: (this.state.currenIndex + 1) % this.state.myArrayLength,
  //     imageCrop:{
  //       unit: "%",
  //       x: "",
  //       y: "",
  //       width: 60,
  //       height: 70
  //     }
  //   })
  // }

  // handlePrev = () => {
  //   this.setState({
  //     currenIndex: (this.state.currenIndex - 1) % this.state.myArrayLength
  //     ,imageCrop:{
  //       unit: "%",
  //       x: "",
  //       y: "",
  //       width: 60,
  //       height: 70
  //     }
  //   })
  // }

  render() {
    const {
      myArray,
      myArrayLength,
      modalVisible,
      currenIndex,
      firstpic,
      secondpic,
      thirdpic,
      fourthpic,
      fifthpic,
    } = this.state;
    if (this.state.redirect) {
      return <Redirect to={"/admin/products"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Product Updated!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warning ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Some Error Occured!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update ExchangeProduct</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Super Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="super_category"
                              required={true}
                              onChange={this.handleCatgory}
                              value={this.state.super_category}
                            >
                              <option value="">Select an Option</option>
                              {this.state.super_categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.title}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Category</label>
                            <br />
                            <select
                              className="form-control"
                              value={this.state.category}
                              name="category"
                              required={true}
                              onChange={this.handleSubCategory}
                            >
                              <option value="">Select an Option</option>
                              {this.state.categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Sub Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="sub_category"
                              required={true}
                              value={this.state.sub_category}
                              onChange={this.handleProductFor}
                            >
                              <option value="">Select an Option</option>
                              {this.state.subCategories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Select Tribe</label>
                            <br />
                            <select
                              className="form-control"
                              name="productFor"
                              value={this.state.productFor}
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.productsFor.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.category_name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              placeholder="Name"
                              name="name"
                              onChange={this.handleInput}
                              value={this.state.name}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>

                        {/* Vendor commission Code */}

                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Vendor</label>
                            <br />
                            <select
                              className="form-control"
                              name="vendor"
                              value={this.state.vendor}
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.vendors.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Vendor Commision</label>
                            <Input
                              placeholder="Vendor Commision"
                              name="commission"
                              onChange={this.handleInput}
                              value={this.state.commission}
                              required={true}
                              type="float"
                            />
                          </FormGroup>
                        </Col> */}

                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Delivery Mode</label>
                            <br />
                            <select
                              className="form-control"
                              value={this.state.deliveryMode}
                              name="deliveryMode"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.deliveryModes.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.delivery_mode}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Tax Type</label>
                            <br />
                            <select
                              className="form-control"
                              name="taxType"
                              value={this.state.taxType}
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.taxTypes.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.tax_type}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>

                        {/* PRINT TYPES COUNT */}

                        {/* <Col className="pr-1" md="3">
                          <label htmlFor="Print Types">Print Types</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.products}
                            value={this.state.productIds}
                            onChange={(data) => {
                              this.setState({ productIds: data });
                              console.log(data)
                            }}
                            labelledBy={"Print Types"}
                          />
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </Col> */}

                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Quantity</label>
                            <Input
                              placeholder="Quantity"
                              value={this.state.quantity}
                              name="quantity"
                              onChange={this.handleInput}
                              required={true}
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Price</label>
                            <Input
                              placeholder="Price"
                              value={this.state.price}
                              name="price"
                              onChange={this.handleInput}
                              required={true}
                              type="float"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Offer Type</label>
                            <br />
                            <select
                              className="form-control"
                              name="offerType"
                              value={this.state.offerType}
                              onChange={this.handleInput}
                            >
                              <option value="">Select Option</option>
                              {["amount", "percent"].map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Offer Value</label>
                            <Input
                              placeholder="Offer Value"
                              name="value"
                              value={this.state.value}
                              onChange={this.handleInput}
                              type="float"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Delivery Charge</label>
                            <Input
                              placeholder="Delivery Charge"
                              name="charge"
                              value={this.state.charge}
                              onChange={this.handleInput}
                              required={true}
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Return Product</label>
                            <select
                              className="form-control"
                              name="is_return"
                              value={this.state.is_return}
                              onChange={this.handleInput}
                            >
                              <option value="yes">Yes</option>

                              <option value="no">No</option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Short Description</label>
                            <Input
                              placeholder="Short Description"
                              value={this.state.short_description}
                              name="short_description"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <label>Image</label>
                          <br />
                          <input
                            type="file"
                            onChange={this.handleFile}
                            // required={true}
                            name="image"
                            multiple
                          />
                        </Col>

                        <Col style={{ marginTop: "8px" }} md="6">
                          <label>Video</label>
                          <br />
                          <input
                            type="file"
                            onChange={this.handleVideo}
                            name="video"
                            accept="video/*"
                          />
                        </Col>
                        {/* =============================== */}

                        <Col style={{ marginTop: "8px" }} md="6">
                          <Grid container spacing={2}>
                            {/* <Grid item sm={4}>
            <label>Image</label>
            <br />
              <input
                type="file"
                accept="image/*"
                name='images1'
                onChange={this.handleImageFile}
                multiple
              />
            </Grid> */}
                            {modalVisible === true ? (
                              <Modal
                                open={modalVisible}
                                onClose={() => {
                                  this.setState({
                                    modalVisible: false,
                                    pics: null,
                                    selectedImage: null,
                                  });
                                }}
                              >
                                <Box
                                  className="modal-size"
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "72  %",
                                    height: "72%",
                                    boxShadow: 24,
                                    p: 4,
                                    overflowY: "scroll",
                                  }}
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <Row
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: 20,
                                      width: 400,
                                      height: 400,
                                    }}
                                  >
                                    {/* <Col> */}
                                    <ReactCrop
                                      src={myArray[currenIndex]}
                                      crop={this.state.imageCrop}
                                      ruleOfThirds
                                      onImageLoaded={this.onImageLoaded}
                                      onComplete={this.onImageCropComplete}
                                      onChange={this.onCropChange}
                                      crossorigin="anonymous"
                                      aspect={4 / 3}
                                    />
                                    {/* </Col> */}
                                    {/* <Col>
                          {firstpic && currenIndex === 0? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={firstpic}
                              />
                            : " "}
                            {secondpic && currenIndex === 1? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={secondpic}
                              />
                            : " "}
                            {thirdpic && currenIndex === 2? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={thirdpic}
                              />
                            : " "}
                            {fourthpic && currenIndex === 3? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={fourthpic}
                              />
                            : " "}
                            {fifthpic && currenIndex === 4? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={fifthpic}
                              />
                            : " "}

                    
                    </Col> */}
                                  </Row>

                                  {myArrayLength === currenIndex + 1 && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="medium"
                                      style={{
                                        position: "absolute",
                                        top: "95%",
                                        right: "41%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: "10",
                                      }}
                                      type="submit"
                                      onClick={this.handleFinish}
                                    >
                                      Finish
                                    </Button>
                                  )}

                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      position: "absolute",
                                      top: "95%",
                                      right: "51%",
                                      transform: "translate(-50%, -50%)",
                                      zIndex: "10",
                                    }}
                                    onClick={this.handlePrev}
                                    disabled={currenIndex === 0}
                                  >
                                    Prev
                                  </Button>

                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "10%",
                                      right: "22%",
                                      transform: "translate(-50%, -50%)",
                                      zIndex: "10",
                                      fontSize: 30,
                                      color: "black",
                                    }}
                                  >
                                    <h1
                                      style={{
                                        textAlign: "center",
                                        color: "black",
                                        fontSize: "2rem",
                                      }}
                                    >
                                      Crop Your Image
                                    </h1>
                                  </div>

                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      position: "absolute",
                                      top: "95%",
                                      right: "33%",
                                      transform: "translate(-50%, -50%)",
                                      zIndex: "10",
                                    }}
                                    onClick={this.handleNext}
                                    disabled={currenIndex === myArrayLength - 1}
                                  >
                                    Next
                                  </Button>
                                </Box>
                              </Modal>
                            ) : (
                              ""
                            )}
                          </Grid>
                          {/* <Button
            variant="contained"
            type="submit"
            size="small"
            color="primary"
            style={{
              marginRight: "20px",
              width: "15%",
              marginTop: "35px",
            }}
          >
            Add
          </Button> */}
                        </Col>

                        <Col md="12">
                          <label>Product Overview</label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={
                              this.props.location.state.data.product_overview
                                ? this.props.location.state.data
                                    .product_overview
                                : ""
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                overview: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col md="12">
                          <label>Note</label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={
                              this.props.location.state.data.note
                                ? this.props.location.state.data.note
                                : ""
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                note: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col md="12">
                          <label>Specification</label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={
                              this.props.location.state.data.specification
                                ? this.props.location.state.data.specification
                                : ""
                            }
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                specification: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading
                              ? "Updating..."
                              : "Update Product"}
                          </Button>
                          <Link to="/admin/exchangeproduct">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                    {/* <Col style={{ marginTop: "8px" }} md="6">
                      <label>Image</label>
                      <br />
                      <input
                        multiple
                        type="file"
                        onChange={this.handleFile1}
                        name="image"
                      />
                      {this.state.imageSelected.length > 0
                        ? this.state.imageSelected.map((img, index) => (
                            <div>
                              <p> {img} </p>
                              <button
                                onClick={() => {
                                  let imageSelected =
                                    this.state.imageSelected.filter(
                                      (imge) => imge !== img
                                    );
                                  console.log(imageSelected);
                                  this.setState({
                                    imageSelected,
                                  });
                                }}
                              >
                                cancel
                              </button>
                            </div>
                          ))
                        : null}
                      {this.state.imageSelected
                        ? this.state.imageSelected.substring(23, 100)
                        : null}
                    </Col> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdateExchangeProduct;
