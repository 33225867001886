import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,UncontrolledAlert,Pagination, PaginationItem, PaginationLink,FormGroup
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
class Advertisements extends Component {
  state = {
    data: [],
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: []
  };
  componentDidMount() {
  this.getCategories()
  }
  getCategories = () => {
    axios
      .get(
        Constants.getUrls.ads+"?limit=" +
        this.state.perPage+"&skip=0&page="+this.state.active
      )
      .then(resp => {
        // console.log(resp.data);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            data: resp.data.docs,
            total: resp.data.totalDocs,
            from: 1,
            to: resp.data.docs.length
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              v => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray
            });
          }
        );
      });
  };
  handlePage = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5
      },
      () => {
        axios
          .get(
            Constants.getUrls.ads+"?limit=" +
            this.state.perPage+"&skip=0&page="+this.state.active
          )
          .then(resp => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.totalPages; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                data: resp.data.docs,
            total: resp.data.totalDocs,
            from: 1,
            to: resp.data.docs.length
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray
                });
              }
            );
          });
      }
    );
  };
  currentPage = v => {
    let commonUrl =
    Constants.getUrls.ads+"?limit=" +
    this.state.perPage+"&skip=0&page=";
    axios.get(commonUrl + v).then(resp => {
      // console.log(resp.data.data);
      this.setState({
        data: resp.data.docs,
        total: resp.data.totalDocs,
        from: v*(this.state.perPage) - (this.state.perPage-1),
        to: v*this.state.perPage > this.state.total ? this.state.total : v * this.state.perPage,
        active: v
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          v => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  v => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                v => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };
  handleStatus = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const payload = {
          status: this.state.status
        };
        axios
          .post(Constants.statusUrl + id + "?type=ad", payload)
          .then(() => {
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              this.setState({notifyStatus:false},()=>{
              this.getCategories();
              });
            }, 1000);
          });
      }
    );
  };
  handleDelete = id => {
    this.setState({ delLoading: true });
    axios
      .delete(Constants.getUrls.ads +"/"+ id)
      .then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false
        });
        setTimeout(() => {
          this.setState({notifyDelete:false,visible:false},()=>{
          this.getCategories();
          });
        }, 2000);
      });
  };
  handleModal = sec => {
    this.setState({
      rowId: sec._id,
      visible: true
    });
  };
  render() {
    return (
      <>
        <div className="content">
          {this.state.notifyStatus ? (
            <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
            <UncontrolledAlert color="success" fade={false}>
            <span className="alert-inner--icon">
              <i className="ni ni-like-2" />
            </span>{" "}
            <span className="alert-inner--text">
              <strong>Status Changed!</strong>
            </span>
          </UncontrolledAlert>
          </div>
          ) : null}
          <Row>
            <Col md="12">
              <Card  style={{ position: "relative" }}>
              <FormGroup style={{
                    position: "absolute",
                    top: "26px",
                    right: "2%",
                    width: "85px"
                  }}>
            <label>Per Page</label>
                            <br />
                            <select
                              className="form-control"
                              name="perPage"
                              value={this.state.perPage}
                              onChange={this.handlePage}
                            >
                              {[10, 25, 50].map((category, i) => (
                                <option key={i} value={category}>
                                  {category}
                                </option>
                              ))}
                            </select>
         </FormGroup>
                <CardHeader>
                  <CardTitle tag="h4">Advertisements Table</CardTitle>
                  <Link to="/admin/addad">
                    <Button className="" color="success">
                      Add Advertisement
                    </Button>
                  </Link>
                </CardHeader>
                <CardBody>
            
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Sr.No</th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Status</th>
                        <th>Actions</th>
                        {/* <th>Delete</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((v, i) => (
                        <tr key={i}>
                          <td style={{position:"relative"}}><span style={{position:"absolute",top:"30px"}}>{i + 1}</span></td>
                          <td style={{position:"relative"}}><span style={{position:"absolute",top:"30px"}}>{v.name}</span></td>
                         <td>
                             {v.image?(
                                 <img src={Constants.imgUrl+v.image} width="80px" alt="img"/>
                             ):"NA"}
                         </td>
                          <td>
                            <select
                              className="form-control"
                              name="status"
                              style={{width:"100px"}}
                              value={v.status}
                              onChange={e => this.handleStatus(e, v._id)}
                            >
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="INACTIVE">INACTIVE</option>
                            </select>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: "/admin/updatead/" + v._id,
                                state: { data: v }
                              }}
                            >
                              <Button
                                className="btn-round"
                                color="info"
                                type="submit"
                              >
                                Update
                              </Button>
                            </Link>
                            <Button
                              className="btn-round"
                              color="danger"
                              type="submit"
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                this.handleModal(v);
                              }}
                            >
                                Delete
                            </Button>
                          </td>
                          {/* <td>
                                  <Button
                                    className="btn-round"
                                    color="primary"
                                    type="submit"
                                  >
                                    Delete
                                  </Button>
                                </td> */}
                        </tr>
                      ))}
                    </tbody>
                    <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Card>
                  {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                  <CardBody>
                    <div>
                      <h3 style={{textAlign:"center"}}>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Advertisement Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <Row style={{display:"flex",justifyContent:"center"}}>
                        <Col xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? "Deleting..." : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="success"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Modal>
                  </Table>
                  {this.state.data.length === 0 ? (
                    <div>
                                            <h6 style={{ textAlign: "center",marginTop:"30px",fontSize:"14px" }}>No Data Found!</h6>
                    </div>
                  ) : <div style={{marginTop:"20px"}}>
                  <div style={{ float: "left" }}>
                    Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
                    entries.
                  </div>
                  
                  <div style={{ float: "right" }}>
                  <Pagination>
            <PaginationItem>
              <PaginationLink
                      onClick={this.prevPages}
                      tabIndex="-1"
              >
                <i className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </PaginationLink>
            </PaginationItem>
                    {this.state.filteredBtnIdArray.map((v, i) => (
                       <PaginationItem className={this.state.active === v ? "active" : ""} key={i}>
                       <PaginationLink   onClick={() => {
                          this.currentPage(v);
                        }}>
                         {v}
                       </PaginationLink>
                     </PaginationItem>
                    ))}
                    <PaginationItem>
              <PaginationLink onClick={this.nextPages}>
                <i className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
                  </div> 
                </div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Advertisements;







// import React from 'react'
// import { Card,CardHeader, CardBody,Input,Table,Row,Col,Button, const [state, dispatch] = useReducer(first, second, third)}
// const Advertisements = () => {
//   return (
//     <div>Advertisements</div>
//   )
// }

// export default Advertisements