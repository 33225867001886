import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import init from "../Helper/WindowToken";
const fd = new FormData();
export default class AddSize extends Component {
  state = {
    name: "",
    size:"",
    image: "",
    description: "",
    notification: false,
    loading: false,
    message: "",
  };

  handleFile = (e) => {
    console.log(e.target.files);
    this.setState({ [e.target.name]: e.target.files[0] });
    console.log(this.state.image);
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  



  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();
    fd.append("size", this.state.size);
    fd.append("title", this.state.name);
    // fd.append("image", this.state.image);
    axios
      .post(Constants.postUrls.addSize, fd)
      .then((resp) => {
        // console.log(resp, "romendra");
        fd.delete("image")
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warn: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warn: false,
            });
          }, 1000);
        }
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/size"} />;
    } else {
      return (
        <div className="content">
          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  {/* <strong> {this.state.message} </strong> */}
                  <strong>Size Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

            {this.state.warn ? (
              <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
              <UncontrolledAlert color="danger" fade={false}>
              <span className="alert-inner--icon">
                <i className="ni ni-dislike-2" />
              </span>{" "}
              <span className="alert-inner--text">
                {/* <strong>Some Error Occured!</strong> */}
                <strong>This Size Already Added!</strong>
              </span>
            </UncontrolledAlert>
            </div>
            ) : null}
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Add Size</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Size</label>
                          <Input
                            placeholder="Size"
                            value={this.state.size}
                            name="size"
                            onChange={this.handleInput}
                            required={true}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col>
                        <FormGroup>
                          <label>Description</label>
                          <Input
                            placeholder="Description"
                            value={this.state.description}
                            name="description"
                            onChange={this.handleInput}
                            required={true}
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                      {/* <Col className="pr-1" md="6">
                        <label>Image</label>
                        <br />
                        <input
                          type="file"
                          name="image"
                          onChange={this.handleFile}
                          // required={true}
                          accept="image/*"
                        />
                      </Col> */}

                      {/* <Col className="pr-1" md="6">
                          <label>Uploaded Image</label>
                          <br />
                          <img
                            src={Constants.imgUrl + this.state.image}
                            alt="image"
                          />
                        </Col>  */}
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <Button
                          className="btn-round"
                          color="success"
                          type="submit"
                        >
                          {this.state.loading ? "Adding..." : "Add Size"}
                        </Button>
                        <Link to="/admin/size">
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
}