import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
class AddDeliveryMode extends Component {
  state = {
    name: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      delivery_mode: this.state.name
    };
    axios
      .post(Constants.getUrls.deliveryModes, payLoad)
     .then((resp) => {
       console.log(resp);
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false
          });
        } else {
          this.setState({
              warn:true,
              loading:false
          });
          setTimeout(() => {
            this.setState({
              warn: false
            });
          }, 1000);
        }
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/deliverymodes"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
              <UncontrolledAlert color="success" fade={false}>
              <span className="alert-inner--icon">
                <i className="ni ni-like-2" />
              </span>{" "}
              <span className="alert-inner--text">
                <strong>Delivery Mode Added!</strong>
              </span>
            </UncontrolledAlert>
            </div>
            ) : null}
            {this.state.warn ? (
              <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
              <UncontrolledAlert color="danger" fade={false}>
              <span className="alert-inner--icon">
                <i className="ni ni-dislike-2" />
              </span>{" "}
              <span className="alert-inner--text">
                <strong>Some Error Occured!</strong>
              </span>
            </UncontrolledAlert>
            </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Delivery Mode</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Delivery Mode</label>
                            <Input
                              placeholder="Delivery Mode"
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Delivery Mode"}
                          </Button>
                          <Link to="/admin/deliverymodes">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddDeliveryMode;
