import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import init from "../Helper/WindowToken";
const fd = new FormData();
export default class AddPaymentImageSliders extends Component {
  state = {
    name: "",
    color: "",
    image: "",
    description: "",
    notification: false,
    loading: false,
    message: "",
    // image Preview
    imageFile: "",
    photoUrl: "",
  };

  handleFile = (e) => {
    console.log(e.target.files);
    this.setState({ [e.target.name]: e.target.files[0] });
    console.log(this.state.image);
  };
  // image Preview handle
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();
    // fd.append("color", this.state.color);
    // fd.append("title", this.state.name);
    fd.append("image", this.state.image);
    axios.post(Constants.postUrls.addPaymentImgSliders, fd).then((resp) => {
      console.log(resp, "romendra");
      fd.delete("image");
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warn: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warn: false,
          });
        }, 1000);
      }
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/paymentImageSliders"} />;
    } else {
      return (
        <div className="content">
          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  {/* <strong> {this.state.message} </strong> */}
                  <strong> PaymentImgSliders Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          {this.state.warn ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-dislike-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  {/* <strong>Some Error Occured!</strong> */}
                  <strong>This PaymentImgSliders Already Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Add PaymentImgSliders</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <Row className="pb-4">
                      {/* <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>PaymentImgSliders</label>
                          <Input
                            placeholder="Color"
                            value={this.state.color}
                            name="color"
                            onChange={this.handleInput}
                            required={true}
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                      {/* <Col>
                        <FormGroup>
                          <label>Description</label>
                          <Input
                            placeholder="Description"
                            value={this.state.description}
                            name="description"
                            onChange={this.handleInput}
                            required={true}
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                      <Col className="pr-1" md="6">
                        <label>Image(1114*350)</label>
                        <br />
                        <input
                          type="file"
                          name="image"
                          onChange={(e) => {
                            this.handleFile(e);
                            this.handlePreview(e);
                          }}
                          // required={true}
                          accept="image/*"
                        />
                      </Col>

                      <Col className="pr-1" md="6">
                        <label>Image preview</label>
                        <br />
                        <img src={this.state.photoUrl} width={100} />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <Button
                          className="btn-round"
                          color="success"
                          type="submit"
                        >
                          {this.state.loading
                            ? "Adding..."
                            : "Add PaymentImageSliders"}
                        </Button>
                        <Link to="/admin/paymentImageSliders">
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
}
