import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import Modal from "react-awesome-modal";

class UpdatePaymentImageSliders extends Component {
  state = {
    name: "",
    color: "",
    id: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    image: "",
    modalVisible: false,
    // data: [],
    // image Preview
    imageFile: "",
    photoUrl: "",
  };
  componentDidMount() {
    let data = this.props.location.state.data;
    this.setState({
      name: data.title,
      id: data._id,
      color: data.color,
      // image: data.image ? data.image : "",
    });
  }

  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
    console.log(this.state.image);
  };
  // image Preview handle
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();
    // fd.append("color", this.state.color);
    // fd.append("title", this.state.name);
    fd.append("image", this.state.image);
    axios
      .put(Constants.getUrls.updatePaymentImgSliders + "/" + this.state.id, fd)
      .then((resp) => {
        fd.delete("image");
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warn: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warn: false,
            });
          }, 1000);
        }
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/paymentImageSliders"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>PaymentImgSliders Updated!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong>This PaymentImgSliders Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update PaymentImgSliders</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        {/* <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Color</label>
                            <Input
                              placeholder="Color"
                              value={this.state.color}
                              name="color"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col> */}
                        <Col className="pr-1" md="6">
                          <label>Image(1114*350)</label>
                          <br />
                          <input
                            type="file"
                            onChange={(e) => {
                              this.handleFile(e);
                              this.handlePreview(e);
                            }}
                            // required={true}
                            name="image"
                          />
                        </Col>
                        <Col className="pr-1" md="6">
                          <label>Image preview</label>
                          <br />
                          <img
                            width={100}
                            src={this.state.photoUrl}
                            alt="image"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="info"
                            type="submit"
                          >
                            {this.state.loading ? "Updating..." : "Update"}
                          </Button>
                          <Link to="/admin/paymentImageSliders">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdatePaymentImageSliders;
