import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Select, TextField } from "@material-ui/core";
import MultiSelect from "react-multi-select-component";
class UpdateBestSeller extends Component {
  state = {
    productIds: [],
    products: [],
    vendors: [],
    vendor: "",
    id: "",
    warn: false,
    redirect: false,
    warning: false,
    notification: false,
    loading: false,
    super_categories: [],
    super_category: "",
    categories: [],
    category: "",
    sub_categories: [],
    sub_category: "",
    search: "",
    all_filter_product: [],
    selectedID: "",
  };
  componentDidMount() {
    // axios
    //   .get(Constants.getUrls.products)
    //   .then(resp => {
    //     // console.log(resp.data);
    //     resp.data.data.forEach(v => {
    //       this.state.products.push({
    //         label: v.name,
    //         value: v._id
    //       });
    //     });
    //     this.setState({
    //       products: this.state.products
    //     }, () => {
    //         let data = this.props.location.state.data;
    //       data.products.forEach(v => {
    //           this.state.productIds.push({
    //             label: v.name,
    //             value: v._id
    //           });
    //         });
    //         this.setState({
    //           productIds: this.state.productIds,
    //           vendor: data.vendor._id,
    //           id: data._id
    //         });
    //       }
    //     );
    //   });
    // axios.get(Constants.getUrls.vendors + "?limit=20&skip=0&page=0").then(resp => {
    //   console.log(resp.data);
    //   let data = this.props.location.state.data;
    //   this.setState({
    //     // products: resp.data.docs,
    //     vendors: resp.data.docs,
    //     vendor: data.vendor._id,
    //     id: data._id
    //   }, () => {
    //     let products = this.state.vendors.find(v => v._id === this.state.vendor).products;
    //     console.log(products);
    //     products.forEach(v => {
    //       this.state.products.push({
    //         label: v.name,
    //         value: v._id
    //       });
    //     });
    //     this.setState({ products: this.state.products }, () => {
    //       let data = this.props.location.state.data;
    //       data.products.forEach(v => {
    //         this.state.productIds.push({
    //           label: v.name,
    //           value: v._id
    //         });
    //       });
    //       this.setState({
    //         productIds: this.state.productIds
    //       });
    //     })
    //   });
    // });

    // Get All Super Category

    let data = this.props.location.state.data;
    // console.log(data);
    let productIds = [];

    this.setState(
      {
        id: window.location.pathname.split("/")[3],
        super_category: data.super_category ? data.super_category._id : "",
        category: data.category ? data.category._id : "",
        sub_category: data.sub_category ? data.sub_category._id : "",
        selectedID: data.products._id,
      },
      () => {
        // console.log(this.state.productIds);
        axios
          .get(
            Constants.getUrls.super_categories +
              "?limit=100" +
              "&skip=0&page=1" +
              "&is_delete=no&status=ACTIVE"
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              super_categories: resp.data.superCat.docs,
            });
          });

        axios
          .get(
            Constants.getUrls.categories +
              "?limit=50&page=1&status=ACTIVE" +
              `&super_category=` +
              this.state.super_category
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              categories: resp.data.docs,
            });
          });

        axios
          .get(
            Constants.getUrls.subCategories +
              "?limit=50&page=1&status=ACTIVE" +
              "&category=" +
              this.state.category +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              sub_categories: resp.data.docs,
            });
          });
        axios
          .get(
            Constants.getUrls.BestproductSearch +
              "?super_category=" +
              this.state.super_category +
              "&category=" +
              this.state.category +
              "&sub_category=" +
              this.state.sub_category +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp);
            if (resp.data.getAllProduct.length == 0) {
              this.setState({
                all_filter_product: [
                  {
                    name: "No Product Found!",
                    _id: "0",
                  },
                ],
              });
            }
            if (resp.data.getAllProduct.length > 0) {
              this.setState({
                all_filter_product: resp.data.getAllProduct,
              });
            }
          });
      }
    );
  }

  handleCategories = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.categories +
              "?limit=50&page=1&status=ACTIVE" +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              categories: resp.data.docs,
            });
          });
      }
    );
  };

  handleSubCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.subCategories +
              "?limit=50&page=1&status=ACTIVE" +
              "&category=" +
              this.state.category +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            console.log(resp);
            this.setState({
              sub_categories: resp.data.docs,
            });
          });
      }
    );
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSearch = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.BestproductSearch +
              "?super_category=" +
              this.state.super_category +
              "&category=" +
              this.state.category +
              "&sub_category=" +
              this.state.sub_category +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp);
            if (resp.data.getAllProduct.length == 0) {
              this.setState({
                all_filter_product: [
                  {
                    name: "No Product Found!",
                    _id: "0",
                  },
                ],
              });
            }
            if (resp.data.getAllProduct.length > 0) {
              this.setState({
                all_filter_product: resp.data.getAllProduct,
              });
            }
          });
      }
    );
  };

  handleSelect = (e, value) => {
    console.log(value);
  };

  // handleVendor = e => {
  //   if (e.target.value !== "") {
  //     this.setState({
  //       vendor: e.target.value
  //     }, () => {
  //       let products = this.state.vendors.find(v => v._id === this.state.vendor).products;
  //       console.log(products);
  //       products.forEach(v => {
  //         this.state.products.push({
  //           label: v.name,
  //           value: v._id
  //         });
  //       });
  //       this.setState({ products: this.state.products, productIds: [] })
  //     });
  //   }
  // };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.selectedID === "") {
      this.setState({ warn: true });
    } else {
      this.setState({
        loading: true,
      });
      let products = [];

      products.push(this.state.selectedID);

      let payLoad = {
        super_category: this.state.super_category,
        category: this.state.category,
        sub_category: this.state.sub_category,
        products,
      };
      axios
        .put(Constants.getUrls.bestSellers + "/" + this.state.id, payLoad)
        .then((resp) => {
          if (resp.data.status === "success") {
            setTimeout(() => {
              this.setState({
                redirect: true,
              });
            }, 1000);
            this.setState({
              notification: true,
              loading: false,
            });
          } else {
            this.setState({
              warning: true,
              loading: false,
            });
            setTimeout(() => {
              this.setState({
                warning: false,
              });
            }, 1000);
          }
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/bestsellers"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Best Seller Updated!</strong>
                    {/* <strong>This BestSeller Already Added!</strong> */}
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warning ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong>This BestSeller Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Best Seller</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        {/* <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Vendor</label>
                            <br />
                            <select
                              className="form-control"
                              name="vendor"
                              value={this.state.vendor}
                              required={true}
                              onChange={this.handleVendor}
                            >
                              <option value="">Select an Option</option>
                              {this.state.vendors.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                        {/* Select Super Category */}
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Super Category</label>
                            <br />
                            <select
                              value={this.state.super_category}
                              className="form-control"
                              name="super_category"
                              required={true}
                              onChange={this.handleCategories}
                            >
                              <option value="">Select an Option</option>
                              {this.state.super_categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.title}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        {/* Select Category */}
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Category</label>
                            <br />
                            <select
                              value={this.state.category}
                              className="form-control"
                              name="category"
                              required={true}
                              onChange={this.handleSubCategory}
                            >
                              <option value="">Select an Option</option>
                              {this.state.categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        {/* Select Sub Category */}
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Sub Category</label>
                            <br />
                            <select
                              value={this.state.sub_category}
                              className="form-control"
                              name="sub_category"
                              required={true}
                              onChange={this.handleSearch}
                            >
                              <option value="">Select Category First!</option>
                              {this.state.sub_categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <label htmlFor="Products">Products</label>
                          {/* <MultiSelect/> */}
                          <select
                            className="form-control"
                            name="products"
                            value={this.state.selectedID}
                            required={true}
                            // onChange={this.handleInput}

                            onChange={(e) => {
                              this.setState({
                                selectedID: e.target.value,
                              });
                            }}
                          >
                            <option value="" style={{ height: "5px" }}>
                              Select an Option
                            </option>
                            {this.state.all_filter_product.map((v, i) => (
                              <option key={i} value={v._id}>
                                {v.name}
                              </option>
                            ))}
                          </select>
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </Col>{" "}
                      </Row>
                      <div
                        style={{
                          height: "10rem",
                        }}
                      ></div>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="info"
                            type="submit"
                          >
                            {this.state.loading
                              ? "Updating..."
                              : "Update Best Seller"}
                          </Button>
                          <Link to="/admin/bestsellers">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdateBestSeller;
