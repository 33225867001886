import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  FormGroup,
  Input,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import Modal from "react-awesome-modal";
import { Link } from "react-router-dom";
import "../css/ToolTipStyle.css";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import { render } from "react-dom";

// var settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
// };

const filterButtons = [
  { label: "Order Confirmed", value: "order-placed" },
  { label: "Shipped", value: "shipped" },
  { label: "Delivered", value: "delivered" },
];
class Rating extends Component {
  state = {
    // orders: [],
    rating: [],
    // filteredOrders: [],
    variant_id: this.props.match.params.id,
    user_id: this.props.match.params.id,
    filterRating: [],
    logistics: [],
    logic: "",
    image: "",
    description: "",
    trackingNo: "",
    delStatus: "order-placed",
    notifyStatus: false,
    loading: false,
    notification: false,
    empty: false,
    details: false,
    status: "",
    rowId: 0,
    status: "",
    active: 1,
    act: 0,
    btnActive: 1,
    btnIdArray: [],
    perPage: "10",
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    filteredBtnIdArray: [],
    message: "",
    notification: false,
    viewVideo: false,
    viewImage: false,
    videoLink: "",
    imageArray: [],
    ratingId: "",
    modalDele: false,
    delLoading: false,
    notifyDelete: false,
    dropDown: false,
    ratingId: "",
  };
  componentDidMount() {
    // getCategories();
    this.getRating();
    // axios
    //   .get(
    //     Constants.getUrls.logistics +
    //       "?limit=20&skip=0&page=0" +
    //       "&is_delete=no"
    //   )
    //   .then((resp) => {
    //     // console.log(resp.data.docs);
    //     this.setState({ logistics: resp.data.docs });
    //   });
  }

  getRating = () => {
    // console.log(Constants.getUrls.getRating
    //   + "?limit=" +
    //   this.state.perPage +
    //   "&skip=0&page=" +
    //   this.state.active +
    //   "&is_delete=no" +
    //   "&sort=true");
    axios
      .get(
        Constants.getUrls.getRating +
          "?limit=" +
          this.state.perPage +
          "&skip=0&page=" +
          this.state.active +
          "&is_delete=no" +
          "&sort=true"
      )
      .then((resp) => {
        console.log(resp.data.data.docs, "ratings");
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.data.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            rating: resp.data.data.docs,
            // filterRating: resp.data.data.docs,
            total: resp.data.data.totalDocs,
            from: 1,
            to: resp.data.data.docs.length,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  deleteRating = (rating_id) => {
    this.setState({ delLoading: true });

    axios
      .delete(Constants.getUrls.getRating + "/" + rating_id)
      .then((response) => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          this.setState(
            { notifyDelete: false, visible: false, modalDele: false },
            () => {
              this.getRating();
            }
          );
        }, 2000);
      });
  };

  handlePage = (e) => {
    // console.log(e.target.value)
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        // console.log(Constants.getUrls.getRating+
        //   "?limit=" +
        //   this.state.perPage +
        //   "&is_delete=no" +
        //   "&sort=true" +
        //   "&skip=0&page=" +
        //   this.state.active);
        axios
          .get(
            Constants.getUrls.getRating +
              "?limit=" +
              this.state.perPage +
              "&is_delete=no" +
              "&sort=true" +
              "&skip=0&page=" +
              this.state.active
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.data.totalPages; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                rating: resp.data.data.docs,
                total: resp.data.data.totalDocs,
                from: 1,
                to: resp.data.data.docs.length,
              },

              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  currentPage = (v) => {
    console.log(v);
    let commonUrl =
      Constants.getUrls.getRating +
      "?limit=" +
      this.state.perPage +
      "&is_delete=no" +
      "&sort=true" +
      "&skip=0&page=";
    console.log(commonUrl);
    axios.get(commonUrl).then((resp) => {
      console.log(resp.data);
      this.setState({
        rating: resp.data.data.docs,
        total: resp.data.data.totalDocs,
        from: v * this.state.perPage - (this.state.perPage - 1),
        to:
          v * this.state.perPage > this.state.total
            ? this.state.total
            : v * this.state.perPage,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios.post(Constants.postUrls.orderStatus + id, payload).then((res) => {
          // console.log(res);
          this.setState({
            notifyStatus: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        });
      }
    );
  };
  handleImageModal = (rating) => {
    this.setState({
      viewImage: true,
      imageArray: rating?.image,
    });
  };

  handleModal = (rating) => {
    this.setState({
      viewVideo: true,
      videoLink: rating?.video,
    });
  };
  handleStatus = (e, rating) => {
    let payload = { status: e.target.value };
    axios
      .post(Constants.getUrls.getRating + "/" + rating._id, payload)
      .then((resp) => {
        console.log(resp);
        this.setState({
          message: resp.data.status,
          notification: true,
        });
        setTimeout(() => {
          this.setState(
            {
              notification: false,
            },
            () => {
              this.getRating();
            }
          );
        }, 2000);
      });
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="bg-black">
                <CardHeader className="bg-black">
                  <CardTitle className="table-content-text" tag="h4">
                    Ratings
                  </CardTitle>
                </CardHeader>
                {this.state.notification ? (
                  <div
                    style={{
                      width: "250px",
                      position: "relative",
                      left: "77%",
                      top: "7px",
                    }}
                  >
                    <UncontrolledAlert color="success" fade={false}>
                      <span className="alert-inner--icon">
                        <i className="ni ni-like-2" />
                      </span>{" "}
                      <span className="alert-inner--text">
                        <strong>{this.state.message}</strong>
                      </span>
                    </UncontrolledAlert>
                  </div>
                ) : null}
                <CardBody className="bg-black shadow-sm">
                  <FormGroup
                    style={{
                      position: "absolute",
                      top: "6px",
                      right: "2%",
                      width: "85px",
                    }}
                  >
                    <label>Per Page</label>
                    <br />
                    <select
                      className="form-control"
                      name="perPage"
                      value={this.state.perPage}
                      onChange={this.handlePage}
                    >
                      {[10, 25, 50].map((category, i) => (
                        <option key={i} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                  {/* <div className="text-center">
                    <label style={{ fontSize: "17px" }}>
                      Filter The Orders
                    </label>
                  </div>
                  {filterButtons.map((btn, index) => (
                    <Button
                      className="btn-round"
                      style={{ marginRight: "20px", marginLeft: "10px" }}
                      color={this.state.act === index ? "danger" : "primary"}
                      onClick={() => {
                        this.handleStatus(btn.value, index);
                      }}
                    >
                      {btn.label}
                    </Button>
                  ))} */}
                  {this.state.rating.map((rating, index) => (
                    <>
                      {/* //////// heading-table ///////// */}
                      <table className="table responsive bg-black borderless main-table">
                        <table className="tablesorter table bg-black borderless">
                          <thead className="borderless">
                            <tr className="table-head-row  d-flex align-items-center">
                              <th className="col-3">
                                Customer Name:{" "}
                                {rating.user?.name ? rating.user?.name : null}{" "}
                              </th>
                              <th className="col-3">
                                Product :
                                {rating.product?.name
                                  ? rating.product?.name
                                  : null}{" "}
                              </th>
                              <th className="col-2">
                                Rating: {rating.rating ? rating.rating : null}
                              </th>

                              <th className="col-4">
                                <div id="wrapper">
                                  <header className="d-flex">
                                    {/* show details */}
                                    <Link>
                                      <div
                                        class="iconDiv"
                                        tooltip="Show Details"
                                        tabindex="0"
                                        onClick={() => {
                                          this.setState({
                                            dropDown: !this.state.dropDown,
                                            ratingId: rating._id,
                                          });
                                        }}
                                      >
                                        <div class="iconSVG">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            stroke-width="1"
                                          >
                                            <g data-name="Layer 2">
                                              <g data-name="eye">
                                                <path
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  d="M21.87 11.5c-.64-1.11-4.16-6.68-10.14-6.5-5.53.14-8.73 5-9.6 6.5a1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25c5.53-.14 8.74-5 9.6-6.5a1 1 0 0 0 0-1zM12.22 17c-4.31.1-7.12-3.59-8-5 1-1.61 3.61-4.9 7.61-5 4.29-.11 7.11 3.59 8 5-1.03 1.61-3.61 4.9-7.61 5z"
                                                ></path>
                                                <path d="M12 8.5a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 12 8.5zm0 5a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z"></path>
                                              </g>
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </Link>
                                    {/* download invoice */}
                                    <Link>
                                      <div
                                        class="iconDiv"
                                        tooltip="Download Invoice"
                                        tabindex="0"
                                      >
                                        <div class="iconSVG">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            stroke-width="1"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </Link>
                                    <div class="spacer"></div>
                                    <div class="divider"></div>
                                    {/* all ratings */}
                                    <Link
                                      to={{
                                        pathname:
                                          "/admin/viewallratings/" +
                                          rating?.product?._id,
                                      }}
                                    >
                                      <div
                                        class="iconDiv"
                                        tooltip="All Ratings"
                                        tabindex="0"
                                      >
                                        <div class="iconSVG">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            stroke-width="1"

                                            // to={
                                            //   "/admin/orderDetails/" +
                                            //   allorders.orders[0].order_id
                                            // }
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </Link>
                                    {/* delete ratings */}
                                    <Link
                                      onClick={() => {
                                        this.setState({
                                          modalDele: true,
                                        });
                                        // this.handleModal(rating, "delete");
                                        console.log(rating._id);
                                      }}
                                    >
                                      <div
                                        class="iconDiv"
                                        tooltip="Delete ratings"
                                        tabindex="0"
                                      >
                                        <div class="iconSVG">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            stroke-width="1"

                                            // to={
                                            //   "/admin/orderDetails/" +
                                            //   allorders.orders[0].order_id
                                            // }
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </Link>
                                  </header>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          {/* <tbody>
                            {this.state.rating.map((rating, index) => (
                              <tr key={index}>
                                <td style={{ width: 10 }}>{index + 1}</td>
                                <td></td>
                                <td></td>
                                <td style={{ width: 100 }}>
                                  {rating.image.length === 0 ? (
                                    "NA"
                                  ) : (
                                    <div>
                                      <Button
                                        className="btn-round"
                                        color="success"
                                        type="submit"
                                        style={{
                                          width: "65px",
                                          height: "45px",
                                        }}
                                        onClick={() => {
                                          this.handleImageModal(rating);
                                        }}
                                      >
                                        
                                        <div className="mongo">
                                          <span class="material-symbols-outlined">
                                            Photo
                                          </span>
                                          <span className="mongo-title">
                                            Image
                                          </span>
                                        </div>
                                      </Button>
                                    </div>
                                  )}
                                </td>
                                <td></td>
                                <td></td>
                                <td style={{ width: 100 }}>
                                  {rating.video ? (
                                    <Button
                                      className="btn-round"
                                      color="warning"
                                      type="submit"
                                      style={{
                                        width: "65px",
                                        height: "45px",
                                      }}
                                      onClick={() => {
                                        this.handleModal(rating, "delete");
                                      }}
                                    >
                                      
                                      <div className="mongo">
                                        <span class="material-symbols-outlined">
                                          Videocam
                                        </span>
                                        <span className="mongo-title">
                                          {" "}
                                          Video
                                        </span>
                                      </div>
                                    </Button>
                                  ) : (
                                    "NA"
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname:
                                        "/admin/viewallratings/" +
                                        rating?.product?._id,
                                      
                                    }}
                                  >
                                    <Button
                                      className="btn-round"
                                      color="info"
                                      type="submit"
                                      
                                      style={{
                                        width: "65px",
                                        height: "45px",
                                      }}
                                    >
                                      <div className="mongo">
                                        <span class="material-symbols-outlined">
                                          Star
                                        </span>
                                        <span className="mongo-title">
                                          Rating
                                        </span>
                                      </div>
                                    </Button>
                                  </Link>
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="status"
                                    style={{ width: "100px" }}
                                    value={rating.status}
                                    onChange={(e) =>
                                      this.handleStatus(e, rating)
                                    }
                                  >
                                    <option value="posted">Posted</option>
                                    <option value="published">Published</option>
                                  </select>
                                </td>
                                <td>
                                  <Button
                                    className="btn-round"
                                    color="danger"
                                    type="submit"
                                    style={{
                                      width: "65px",
                                      height: "45px",
                                    }}
                                    onClick={() => {
                                      this.setState({ modalDele: true });
                                      console.log(rating._id);
                                    }}
                                  >
                                    <div className="mongo ">
                                      <span class="material-symbols-outlined ">
                                        Delete
                                      </span>
                                      <span className="mongo-title ">
                                        {" "}
                                        Delete
                                      </span>
                                    </div>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody> */}
                        </table>
                      </table>
                      {/* ////// order-dropdown-details-table ///////// */}
                      <table
                        className={`table responsive bg-black borderless main-table borderless ${
                          this.state.dropDown &&
                          rating._id === this.state.ratingId
                            ? "d-block"
                            : "d-none"
                        }`}
                      >
                        <table className="tablesorter table bg-black borderless">
                          <thead className="borderless">
                            <tr className="table-head-row  d-flex align-items-center">
                              <th>
                                Review: {rating.review ? rating.review : null}
                              </th>
                              <th>
                                Image:{" "}
                                {rating.image.length === 0 ? (
                                  "NA"
                                ) : (
                                  <div>
                                    <Button
                                      className="btn-round"
                                      color="success"
                                      type="submit"
                                      style={{
                                        width: "65px",
                                        height: "45px",
                                      }}
                                      onClick={() => {
                                        this.handleImageModal(rating);
                                      }}
                                    >
                                      {/* View */}
                                      <div className="mongo">
                                        <span class="material-symbols-outlined">
                                          Photo
                                        </span>
                                        <span className="mongo-title">
                                          Image
                                        </span>
                                      </div>
                                    </Button>
                                  </div>
                                )}{" "}
                              </th>
                              <th>
                                Video:{" "}
                                {rating.video ? (
                                  <Button
                                    className="btn-round"
                                    color="warning"
                                    type="submit"
                                    style={{
                                      width: "65px",
                                      height: "45px",
                                    }}
                                    onClick={() => {
                                      this.handleModal(rating, "delete");
                                    }}
                                  >
                                    {/* View */}
                                    <div className="mongo">
                                      <span class="material-symbols-outlined">
                                        Videocam
                                      </span>
                                      <span className="mongo-title">
                                        {" "}
                                        Video
                                      </span>
                                    </div>
                                  </Button>
                                ) : (
                                  "NA"
                                )}{" "}
                              </th>
                              {/* <th>
                                View All Rating:{" "}
                                <Link>
                                  <Button
                                    className="btn-round"
                                    color="info"
                                    type="submit"
                                    // style={{ marginRight: "10px" }}
                                    style={{
                                      width: "65px",
                                      height: "45px",
                                    }}
                                  >
                                    <div className="mongo">
                                      <span class="material-symbols-outlined">
                                        Star
                                      </span>
                                      <span className="mongo-title">
                                        Rating
                                      </span>
                                    </div>
                                  </Button>
                                </Link>
                              </th> */}
                              <th className="d-flex align-items-center">
                                <span>Status:</span>
                                {""}
                                <select
                                  className="form-control"
                                  name="status"
                                  style={{ width: "200px" }}
                                  value={rating.status}
                                  onChange={(e) => this.handleStatus(e, rating)}
                                >
                                  <option value="posted">Posted</option>
                                  <option value="published">Publis</option>
                                </select>
                              </th>
                              {/* <th>
                                Delete:{" "}
                                <Button
                                  className="btn-round"
                                  color="danger"
                                  type="submit"
                                  style={{
                                    width: "65px",
                                    height: "45px",
                                  }}
                                  onClick={() => {
                                    this.setState({ modalDele: true });
                                    
                                    console.log(rating._id);
                                  }}
                                >
                                  <div className="mongo ">
                                    <span class="material-symbols-outlined ">
                                      Delete
                                    </span>
                                    <span className="mongo-title ">
                                      {" "}
                                      Delete
                                    </span>
                                  </div>
                                </Button>
                              </th> */}
                            </tr>
                          </thead>
                        </table>
                      </table>
                    </>
                  ))}
                  {/* logistics !? */}
                  <Modal
                    visible={this.state.visible}
                    width="700"
                    height="330"
                    effect="fadeInUp"
                    onClickAway={() => this.setState({ visible: false })}
                  >
                    <Row>
                      <Col md="12">
                        <Card>
                          <CardBody>
                            {this.state.details ? (
                              <Row>
                                <Col md="6">
                                  {this.state.image ? (
                                    <img
                                      width="100%"
                                      alt=""
                                      src={Constants.imgUrl + this.state.image}
                                    ></img>
                                  ) : (
                                    "Image Not Available"
                                  )}
                                </Col>
                                <Col md="6">
                                  {this.state.description ? (
                                    <div>{this.state.description}</div>
                                  ) : (
                                    "Description Not Available"
                                  )}
                                </Col>
                              </Row>
                            ) : (
                              <form>
                                <Row>
                                  <Col md="6">
                                    <label>Logistics</label>
                                    <br />
                                    <select
                                      style={{
                                        padding: "11px",
                                        width: "100%",
                                      }}
                                      name="logic"
                                      // value={this.state.logic}
                                      onChange={this.handleInput}
                                    >
                                      <select value="">Select</select>
                                      {this.state.logistics.map((v, i) => (
                                        <option key={i} value={v._id}>
                                          {v.name}
                                        </option>
                                      ))}
                                    </select>
                                  </Col>
                                  <Col xs={12} sm={12} md={6}>
                                    <FormGroup>
                                      <label>Tracking No</label>
                                      <Input
                                        placeholder="Tracking No"
                                        name="trackingNo"
                                        onChange={this.handleInput}
                                        required={true}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col xs={12} sm={12} md={4}>
                                    {this.state.notification ? (
                                      <div>
                                        <span style={{ color: "green" }}>
                                          Shipping Details Added Successfuly!
                                        </span>
                                      </div>
                                    ) : null}
                                    <Button
                                      className=""
                                      color="warning"
                                      onClick={this.addShipping}
                                    >
                                      {this.state.loading ? "Adding..." : "Add"}
                                    </Button>
                                    <Button
                                      className=""
                                      color="danger"
                                      onClick={() => {
                                        this.setState({ visible: false });
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </Col>
                                </Row>
                              </form>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Modal>
                  {/* show video */}
                  <Modal
                    style={{ textAlign: "center" }}
                    visible={this.state.viewVideo}
                    width="550"
                    height="200"
                    effect="fadeInUp"
                    onClickAway={() => this.setState({ viewVideo: false })}
                  >
                    <Row>
                      <Col md="12">
                        <Card>
                          <CardBody>
                            {/* {this.state.imageArray.length > 0 &&
                                                       this.state.imageArray.map((item) => (
                                                         <img
                                                           width="80px"
                                                           alt=""
                                                           src={Constants.imgUrl + item}
                                                         />
                                                       ))} */}
                            <video
                              controls
                              autoPlay
                              src={Constants.imgUrl + this.state.videoLink}
                            ></video>

                            {/* <video
                                                       src={Constants.imgUrl + this.state.videoLink}
                                                       autoplay
                                                     ></video> */}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Modal>
                  {/* show images */}
                  <Modal
                    style={{ textAlign: "center" }}
                    visible={this.state.viewImage}
                    width="550"
                    height="200"
                    effect="fadeInUp"
                    onClickAway={() => this.setState({ viewImage: false })}
                  >
                    <Row>
                      <Col md="12">
                        <Card>
                          <CardBody>
                            <div className="row justify-content-center align-items-center">
                              {this.state.imageArray.length > 0
                                ? this.state.imageArray.map((image) => (
                                    <div className="col-3">
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          objectFit: "cover",
                                        }}
                                        src={Constants.imgUrl + image}
                                        alt=""
                                      />
                                    </div>
                                  ))
                                : "NA"}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Modal>
                  {/* review delete */}
                  <Modal
                    style={{ textAlign: "center" }}
                    visible={this.state.modalDele}
                    width="550"
                    height="200"
                    effect="fadeInUp"
                    onClickAway={() => this.setState({ modalDele: false })}
                  >
                    <Row>
                      <Col md="12">
                        <Card>
                          <CardBody>
                            <h3 style={{ textAlign: "center" }}>
                              Are You Sure You Want To Delete??
                              <br />
                              Press YES to confirm
                            </h3>
                            {this.state.notifyDelete ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Review Deleted Successfuly!
                                </span>
                              </div>
                            ) : null}
                            <Row
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {this.state.rating.map((rating, index) => (
                                <Col key={index} xs={12} sm={12} md={4}>
                                  <br />
                                  <Button
                                    className=""
                                    color="warning"
                                    onClick={() => {
                                      this.deleteRating(rating._id);
                                    }}
                                  >
                                    {this.state.delLoading
                                      ? "Deleting..."
                                      : "Yes"}
                                  </Button>
                                  <Button
                                    className=""
                                    color="success"
                                    onClick={() => {
                                      this.setState({ visible: false });
                                    }}
                                  >
                                    No
                                  </Button>
                                </Col>
                              ))}
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Modal>

                  {this.state.rating.length === 0 ? (
                    <div>
                      <h6
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          fontSize: "14px",
                        }}
                      >
                        No Data Found!
                      </h6>
                    </div>
                  ) : (
                    <div style={{ marginTop: "20px" }}>
                      <div style={{ float: "left" }}>
                        Showing {this.state.from} to {this.state.to} of{" "}
                        {this.state.total} entries.
                      </div>

                      <div style={{ float: "right" }}>
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.prevPages}
                              tabIndex="-1"
                            >
                              <i className="fa fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          {this.state.filteredBtnIdArray.map((v, i) => (
                            <PaginationItem
                              className={
                                this.state.active === v ? "active" : ""
                              }
                              key={i}
                            >
                              <PaginationLink
                                onClick={() => {
                                  this.currentPage(v);
                                }}
                              >
                                {v}
                              </PaginationLink>
                            </PaginationItem>
                          ))}
                          <PaginationItem>
                            <PaginationLink onClick={this.nextPages}>
                              <i className="fa fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </div>
                  )}
                  {/* {this.state.rating.length === 0 ? (
                    <div>
                      <h6
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          fontSize: "14px",
                        }}
                      >
                        No Data Found!
                      </h6>
                    </div>
                  ) : this.state.empty ? null : (
                    <div style={{ marginTop: "20px" }}>
                      <div style={{ float: "left" }}>
                        Showing {this.state.from} to {this.state.to} of{" "}
                        {this.state.total} entries.
                      </div>

                      <div style={{ float: "right" }}>
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.prevPages}
                              tabIndex="-1"
                            >
                              <i className="fa fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          {this.state.filteredBtnIdArray.map((v, i) => (
                            <PaginationItem
                              className={
                                this.state.active === v ? "active" : ""
                              }
                              key={i}
                            >
                              <PaginationLink
                                onClick={() => {
                                  this.currentPage(v);
                                }}
                              >
                                {v}
                              </PaginationLink>
                            </PaginationItem>
                          ))}
                          <PaginationItem>
                            <PaginationLink onClick={this.nextPages}>
                              <i className="fa fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </div>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Rating;
