import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  FormGroup,
  Input,
  Col,
  Button,
  Pagination,
  UncontrolledAlert,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import Modal from "react-awesome-modal";
import { Link } from "react-router-dom";

export default class ViewAllRating extends Component {
  state = {
    rating: [],
    productId: "",
    notification: false,
    viewVideo: false,
    message: "",
    notifyStatus: false,
    notifyDelete: false,
    visible: false,
    delLoading: false,
    rowId: 0,
    status: "",
    active: 1,
    perPage: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    viewImage: false,
    ratingId: "",
    videoLink: "",

    imageArray: [],
  };
  componentDidMount() {
    let id = window.location.pathname.split("/")[3];
    this.setState(
      {
        productId: id,
      },
      () => {
        this.getAllRatingsProductWise();
      }
    );
  }
  getAllRatingsProductWise = () => {
    axios
      .get(Constants.getUrls.getRating + "?product_id=" + this.state.productId)
      .then((resp) => {
        console.log(resp);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.data.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            rating: resp?.data?.data?.docs,
            total: resp.data.data.totalDocs,
            from: 1,
            to: resp.data.data.docs.length,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  handlePage = (e) => {
    // console.log(e.target.value)
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        // console.log(Constants.getUrls.getRating+
        //   "?limit=" +
        //   this.state.perPage +
        //   "&is_delete=no" +
        //   "&sort=true" +
        //   "&skip=0&page=" +
        //   this.state.active);
        axios
          .get(
            Constants.getUrls.getRating +
              "?product_id=" +
              this.state.productId +
              "?limit=" +
              this.state.perPage +
              "&is_delete=no" +
              "&sort=true" +
              "&skip=0&page=" +
              this.state.active
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.data.totalPages; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                rating: resp.data.data.docs,
                total: resp.data.data.totalDocs,
                from: 1,
                to: resp.data.data.docs.length,
              },

              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  currentPage = (v) => {
    console.log(v);
    let commonUrl =
      Constants.getUrls.getRating +
      "?product_id=" +
      this.state.productId +
      "?limit=" +
      this.state.perPage +
      "&is_delete=no" +
      "&sort=true" +
      "&skip=0&page=";
    console.log(commonUrl);
    axios.get(commonUrl).then((resp) => {
      console.log(resp.data);
      this.setState({
        rating: resp.data.data.docs,
        total: resp.data.data.totalDocs,
        from: v * this.state.perPage - (this.state.perPage - 1),
        to:
          v * this.state.perPage > this.state.total
            ? this.state.total
            : v * this.state.perPage,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleImageModal = (rating) => {
    this.setState({
      viewImage: true,
      imageArray: rating?.image,
    });
  };

  handleModal = (rating) => {
    this.setState({
      viewVideo: true,
      videoLink: rating?.video,
    });
  };
  handleStatus = (e, rating) => {
    let payload = { status: e.target.value };
    axios
      .post(Constants.getUrls.getRating + "/" + rating._id, payload)
      .then((resp) => {
        console.log(resp);
        this.setState({
          message: resp.data.status,
          notification: true,
        });
        setTimeout(() => {
          this.setState(
            {
              notification: false,
            },
            () => {
              this.getAllRatingsProductWise();
            }
          );
        }, 2000);
      });
  };
  render() {
    return (
      <>
        {/* <div>View all Ratings</div> */}
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Product All Ratings</CardTitle>
                </CardHeader>
                {this.state.notification ? (
                  <div
                    style={{
                      width: "250px",
                      position: "relative",
                      left: "77%",
                      top: "7px",
                    }}
                  >
                    <UncontrolledAlert color="success" fade={false}>
                      <span className="alert-inner--icon">
                        <i className="ni ni-like-2" />
                      </span>{" "}
                      <span className="alert-inner--text">
                        <strong>{this.state.message}</strong>
                      </span>
                    </UncontrolledAlert>
                  </div>
                ) : null}
                <CardBody>
                  <FormGroup
                    style={{
                      position: "absolute",
                      top: "6px",
                      right: "2%",
                      width: "85px",
                    }}
                  >
                    <label>Per Page</label>
                    <br />
                    <select
                      className="form-control"
                      name="perPage"
                      value={this.state.perPage}
                      onChange={this.handlePage}
                    >
                      {[10, 25, 50].map((category, i) => (
                        <option key={i} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Sr.No</th>
                        <th>User Name</th>
                        <th>Product Name</th>
                        <th>Product Image</th>
                        <th>Rating</th>
                        <th>Review</th>
                        {/* <th>User Images</th> */}
                        <th>Video</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rating.map((rating, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {rating.user?.name ? rating.user?.name : null}
                          </td>
                          <td>
                            {rating.product?.name ? rating.product?.name : null}
                          </td>
                          <td style={{ width: 100 }}>
                            {rating.image.length === 0 ? (
                              "NA"
                            ) : (
                              <div>
                                <Button
                                  className="btn-round"
                                  color="success"
                                  type="submit"
                                  style={{
                                    width: "65px",
                                    height: "45px",
                                  }}
                                  onClick={() => {
                                    this.handleImageModal(rating);
                                  }}
                                >
                                  {/* View */}
                                  <div className="mongo">
                                    <span class="material-symbols-outlined">
                                      Photo
                                    </span>
                                    <span className="mongo-title">Image</span>
                                  </div>
                                </Button>
                              </div>
                            )}
                          </td>
                          <td>{rating.rating ? rating.rating : null}</td>
                          <td>{rating.review ? rating.review : null}</td>
                          <td style={{ width: 100 }}>
                            {rating.video ? (
                              <Button
                                className="btn-round"
                                color="warning"
                                type="submit"
                                style={{
                                  width: "65px",
                                  height: "45px",
                                }}
                                onClick={() => {
                                  this.handleModal(rating, "delete");
                                }}
                              >
                                {/* View */}
                                <div className="mongo">
                                  <span class="material-symbols-outlined">
                                    Videocam
                                  </span>
                                  <span className="mongo-title"> Video</span>
                                </div>
                              </Button>
                            ) : (
                              "NA"
                            )}
                          </td>
                          {/* <td>
                            <Button
                              className="btn-round"
                              color="info"
                              type="submit"
                              style={{ marginLeft: "-33px" }}
                              onClick={() => {
                                this.handleModal(rating, "delete");
                              }}
                            >
                              View Video
                            </Button>
                          </td> */}

                          <td>
                            <select
                              className="form-control"
                              name="status"
                              style={{ width: "100px" }}
                              value={rating.status}
                              onChange={(e) => this.handleStatus(e, rating)}
                            >
                              <option value="posted">Posted</option>
                              <option value="published">Published</option>
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <Modal
                      visible={this.state.visible}
                      width="700"
                      height="330"
                      effect="fadeInUp"
                      onClickAway={() => this.setState({ visible: false })}
                    >
                      <Row>
                        <Col md="12">
                          <Card>
                            <CardBody>
                              {this.state.details ? (
                                <Row>
                                  <Col md="6">
                                    {this.state.image ? (
                                      <img
                                        width="100%"
                                        alt=""
                                        src={
                                          Constants.imgUrl + this.state.image
                                        }
                                      ></img>
                                    ) : (
                                      "Image Not Available"
                                    )}
                                  </Col>
                                  <Col md="6">
                                    {this.state.description ? (
                                      <div>{this.state.description}</div>
                                    ) : (
                                      "Description Not Available"
                                    )}
                                  </Col>
                                </Row>
                              ) : null}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Modal>
                    <Modal
                      visible={this.state.viewVideo}
                      width="700"
                      height="330"
                      effect="fadeInUp"
                      onClickAway={() => this.setState({ viewVideo: false })}
                    >
                      <Row>
                        <Col md="12">
                          <Card>
                            <CardBody>
                              <video
                                controls
                                autoplay
                                src={Constants.imgUrl + this.state.videoLink}
                              ></video>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Modal>
                  </Table>
                  <Modal
                    style={{ textAlign: "center" }}
                    visible={this.state.viewImage}
                    width="550"
                    height="200"
                    effect="fadeInUp"
                    onClickAway={() => this.setState({ viewImage: false })}
                  >
                    <Row>
                      <Col md="12">
                        <Card>
                          <CardBody>
                            <div className="row justify-content-center align-items-center">
                              {this.state.imageArray.length > 0
                                ? this.state.imageArray.map((image) => (
                                    <div className="col-3">
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          objectFit: "cover",
                                        }}
                                        src={Constants.imgUrl + image}
                                        alt=""
                                      />
                                    </div>
                                  ))
                                : "NA"}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Modal>
                  {this.state.rating.length === 0 ? (
                    <div>
                      <h6
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          fontSize: "14px",
                        }}
                      >
                        No Data Found!
                      </h6>
                    </div>
                  ) : (
                    <div style={{ marginTop: "20px" }}>
                      <div style={{ float: "left" }}>
                        Showing {this.state.from} to {this.state.to} of{" "}
                        {this.state.total} entries.
                      </div>

                      <div style={{ float: "right" }}>
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.prevPages}
                              tabIndex="-1"
                            >
                              <i className="fa fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          {this.state.filteredBtnIdArray.map((v, i) => (
                            <PaginationItem
                              className={
                                this.state.active === v ? "active" : ""
                              }
                              key={i}
                            >
                              <PaginationLink
                                onClick={() => {
                                  this.currentPage(v);
                                }}
                              >
                                {v}
                              </PaginationLink>
                            </PaginationItem>
                          ))}
                          <PaginationItem>
                            <PaginationLink onClick={this.nextPages}>
                              <i className="fa fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
